/**
 * The path to icon folder.
 *
 * @type {String}
 */
const ICON_PATH = "https://d1dijnkjnmzy2z.cloudfront.net/icons";

/**
 * Custom async foreach loop
 *
 * @param {Array} array
 * @param {function} callback
 */
export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

/**
 * Get the URL of some icon in CDN
 *
 * @param {string} path
 * @returns
 */
export const getIcon = path => {
  return `${ICON_PATH}/${path}`;
};

export const getDomainIcon = tld => {
  const available = [
    "academy",
    "accountants",
    "adult",
    "africa",
    "agency",
    "ai",
    "app",
    "army",
    "attorney",
    "au",
    "best",
    "bike",
    "biz",
    "business",
    "bz",
    "ca",
    "cafe",
    "cards",
    "care",
    "careers",
    "cc",
    "center",
    "ch",
    "chat",
    "church",
    "city",
    "clinic",
    "clothing",
    "club",
    "co",
    "coach",
    "codes",
    "coffee",
    "com",
    "community",
    "company",
    "cool",
    "dance",
    "day",
    "de",
    "deals",
    "dev",
    "digital",
    "direct",
    "discount",
    "doctor",
    "dog",
    "email",
    "energy",
    "es",
    "events",
    "expert",
    "express",
    "farm",
    "fashion",
    "fitness",
    "fm",
    "forsale",
    "foundation",
    "fr",
    "fund",
    "games",
    "gay",
    "gg",
    "gold",
    "golf",
    "group",
    "guide",
    "hockey",
    "holdings",
    "how",
    "id",
    "in",
    "industries",
    "institute",
    "io",
    "is",
    "jewelry",
    "kitchen",
    "land",
    "legal",
    "life",
    "live",
    "llc",
    "marketing",
    "media",
    "mx",
    "name",
    "net",
    "network",
    "new",
    "news",
    "ninja",
    "nyc",
    "online",
    "org",
    "page",
    "pe",
    "photography",
    "photos",
    "pictures",
    "pizza",
    "properties",
    "recipes",
    "rentals",
    "report",
    "restaurant",
    "rocks",
    "sale",
    "school",
    "services",
    "sg",
    "sh",
    "shop",
    "so",
    "social",
    "solutions",
    "store",
    "studio",
    "systems",
    "tax",
    "taxi",
    "tienda",
    "tires",
    "to",
    "today",
    "tokyo",
    "tours",
    "town",
    "toys",
    "training",
    "travel",
    "tv",
    "uk",
    "vacations",
    "vc",
    "vegas",
    "ventures",
    "villas",
    "vote",
    "wine",
    "world",
    "zone",
  ];
  return `${ICON_PATH}/redesign/domain-${available.includes(tld) ? tld : "gg"}.svg`;
};
