import { tldExists as tldJsExists } from "tldjs";
import { getHnsTlds } from "utils/backend";

const splitDomain = domain => {
  const parts = domain.split(".");
  parts.push(parts.splice(1).join("."));
  return parts;
};

export const tldExists = async query => {
  try {
    const _tldExists = tldJsExists(query);
    const hnsTlds = await getHnsTlds();
    const { 1: tld } = splitDomain(query);
    const _hnsTldExists = hnsTlds.includes(tld);

    return _tldExists || _hnsTldExists;
  } catch (error) {
    return tldJsExists(query);
  }
};
