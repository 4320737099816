import plans from "data/plans";
import productsJson from "data/products.json";
import descriptions from "data/descriptions";
import { reputationManager } from "utils/experiment";
import { asyncForEach } from "utils/functions";
import { getProductPrice, getRTBProducts } from "utils/backend";

/**
 * Product utilities.
 *
 * @author Gustavo Straube <gustavo@kettle.io>
 */

/**
 * Current env.
 *
 * @type {String}
 */
const env = process.env.REACT_APP_ENV === "production" ? "production" : "sandbox";

/**
 * The product slugs.
 *
 * @type {Object.<Number, String>}
 */
const SLUGS = {
  1: "stellar",
  5: "ox-starter",
  3: "ssl",
  2: "easy-wp",
  4: "vpn",
  6: "logo-maker",
  8: "logo-maker", // Duplicate for production
  9: "business-card-maker",
  10: "gsuite",
  11: "sitemaker",
  85: "ox-starter",
  909090: "review-manager",
  909091: "reputation-manager",
  909092: "manage-your-reputation",
  909093: "manage-your-business-reviews",
  2021032901: "identity-theft-insurance",
  2021032902: "business-insurance",
  2021041901: "local-listing-manager",
  2021041902: "local-listings",
  2021041903: "directory-listings",
  2021060101: "ad-manager-faster",
  2021060102: "ad-manager-create",
  2021060103: "easy-ads-maker-faster",
  2021060104: "easy-ads-maker-create",
  "premium-dns": "premium-dns",
  "social-media-manager": "social-media-manager",
  "grow-your-social": "grow-your-social",
  stencil: "stencil",
};

/**
 * The product slugs based on RTB Endpoint
 *
 * @type {Object.<Number, String>}
 */
const RTB_SLUGS = {
  1: "stellar",
  2: "easy-wp",
  3: "ssl",
  4: "vpn",
  5: "ox-starter",
  6: "logo-maker",
  8: "logo-maker", // Duplicate for production
  9: "business-card-maker",
  10: "gsuite",
  11: "premium-dns",
  12: "social-media-manager",
  13: "review-manager",
  1000: "sitemaker",
  2000: "email-marketing-tool",
  sitelock: "sitelock",
  "seo-manager": "seo-manager",
};

/**
 * The product intervals based on cart endpoint
 *
 * @type {Object.<Number, String>}
 */
export const DURATION_TYPES = {
  6: "mo",
  7: "yr",
};

export const EASY_WP_PLANS = {
  120: "starter",
  121: "starter",
  1210: "starter-trial",
  124: "turbo",
  1240: "turbo",
  125: "turbo",
  122: "supersonic",
  1220: "supersonic",
  123: "supersonic",
  1230: "supersonic",
};

export const EASY_WP_PLANS_BY_SLUG = {
  "ewp-starter-mo": 121,
  "ewp-starter-yr": 120,
  "ewp-turbo-mo": 124,
  "ewp-turbo-yr": 125,
  "ewp-supersonic-mo": 122,
  "ewp-supersonic-yr": 123,
  "ewp-starter-trial": 1210,
};

export const RELATE_PLANS = {
  721: "social-essentials", // monthly
  7210: "social-essentials", // monthly trial
  722: "social-pro", // monthly
  7220: "social-pro", // monthly trial
  723: "reviews-essentials", // monthly
  7230: "reviews-essentials", // monthly trial
  724: "reviews-pro", // monthly
  725: "social-pro", // Yearly
  726: "social-essentials", // Yearly
  728: "reviews-essentials", // Yearly
  727: "reviews-pro", // Yearly
};

export const RELATE_PLANS_WITH_INTERVAL = {
  "social-essentials-mo": 721, // monthly
  "social-essentials-mo-free": 7210, // monthly trial
  "social-pro-mo": 722, // monthly
  "social-pro-mo-free": 7220, // monthly trial
  "reviews-essentials-mo": 723, // monthly
  "reviews-essentials-mo-free": 7230, // monthly trial
  "reviews-pro-mo": 724, // monthly
  "social-pro-yr": 725, // Yearly
  "social-essentials-yr": 726, // Yearly
  "reviews-essentials-yr": 728, // Yearly
  "reviews-pro-yr": 727, // Yearly
};

/**
 * Get the relate Pricing plan ID
 * @param {String} slug
 * @return {String | null}
 */
export const getRelatePlan = slug => {
  slug = slug.replace("essential-", "essentials-").replace("review-", "reviews-");
  return RELATE_PLANS_WITH_INTERVAL[slug];
};

/**
 * Get the product slug given its ID.
 *
 * @param  {Number} id
 * @return {String}
 */
export const getProductSlug = id => {
  return RTB_SLUGS[id];
};

/**
 * Get the plans for a product given its slug.
 *
 * @param  {String} slug
 * @return {Promise<Object|null>}
 */
export const getPlans = async slug => {
  try {
    let _plans = plans[env][slug];
    if (_plans) {
      await asyncForEach(_plans.list, async (plan, _index) => {
        const id = plan.id.replace(/(easy-wp)/, "ewp");
        let [_slug, planId = null] = id.split("-");

        if (slug === "social-media-manager" || slug === "review-manager") {
          _slug = slug;
          planId = id;
        }

        const interval =
          (plan.duration.amount > 1 ? plan.duration.amount : "") + plan.duration.type;
        const _product = await getProductPrice(_slug, planId, interval, false, true);
        if (_product) {
          plan.price = _product.price;
          plan.free_trial = _product.free_trial;
          if (_product.renewal_price) {
            plan.priceRenewal = _product.renewal_price;
          }

          if (_slug === "ewp") {
            if (_product.free_trial && plan.plan_id.toString().length === 3) {
              plan.plan_id = parseInt(`${plan.plan_id}0`);
            }
          }
        }

        return plan;
      });
    }

    return _plans;
  } catch (e) {
    return null;
  }
};

/**
 * Get the product description based on product slug + plan
 *
 * @param {*} slug
 * @return {String}
 */
export const getDescription = slug => {
  try {
    const item = descriptions.find(item => item.id === slug);
    return item ? item.description : null;
  } catch (e) {
    return null;
  }
};

/**
 * Map the banditML product slugs to products
 *
 * @param   {Array<String>} Slugs of products to convert in string
 * @param   {Array<Object>} List of products/addons
 * @param   {String} variant slug
 *
 * @return  {Array<Object>} list of addons
 */
export const mapBanditProducts = async (decisions, products, variantSlug) => {
  const addons = [];

  await asyncForEach(decisions, async decision => {
    if (decision === "social-media-manager") {
      decision = "social-media-manager-mo";
    }

    if (decision === "grow-your-social") {
      decision = "grow-your-social-mo";
    }

    if (decision === "premium-dns") {
      decision = "premium-dns-yr";
    }

    if (isListingProduct(decision)) {
      decision = decision + "-mo";
    }

    if (decision === "identity-theft-insurance" || decision === "business-insurance") {
      decision = decision + "-mo";
    }

    if (reputationManager.indexOf(decision) >= 0) {
      decision = decision + "-mo";
    }

    if (isAdManagerProduct(decision)) {
      decision = decision + "-mo";
    }

    let parts = decision.split("-"),
      productId = null,
      planId = null,
      interval = null,
      plans = { list: [] },
      addon = null,
      product = null;
    let _description = null;
    interval = parts.pop();

    if (decision.includes("easy-wp") || decision.includes("stellar") || decision.includes("ox")) {
      if (parts.length > 1) {
        planId = parts.pop();
      }
    }

    productId = parts.join("-");
    let _firstPart = parts[0];
    if (productId.includes("easy-wp")) {
      _firstPart = "ewp";
    }

    switch (_firstPart) {
      case "ewp":
        plans = await getPlans("easy-wp");
        product = products.find(product => product.slug === "easy-wp");
        break;
      case "stellar":
        plans = await getPlans("stellar");
        product = products.find(product => product.slug === "stellar");
        break;
      case "ox":
        plans = await getPlans("ox-starter");
        product = products.find(product => product.slug === "ox-starter");
        break;
      case "gsuite":
        plans = await getPlans("gsuite");
        product = products.find(product => product.slug === "gsuite");
        break;
      case "vpn":
        plans = await getPlans("vpn");
        product = products.find(product => product.slug === "vpn");
        break;
      default:
        product = products.find(
          product => product.slug === productId && product.interval === interval
        );
        if (product) {
          _description = getDescription(product.slug);
          let rtbProduct = await getProductPrice(product.slug, null, interval, true, true);

          addon = {
            ...product,
            ...parseRTBProductFields(rtbProduct),
          };
          addons.push(addon);
          return;
        }
    }

    if (plans && plans.list) {
      _description = getDescription(planId ? `${productId}-${planId}` : productId);

      const plan = plans.list
        .filter(plan => plan.id === (planId ? `${productId}-${planId}` : productId))
        .find(plan => plan.duration.type === interval);
      const rtbProduct = await getProductPrice(product.slug, null, interval, true, true);

      if (plan) {
        if (
          (variantSlug.includes("gmail") ||
            variantSlug.includes("gsuite") ||
            variantSlug.includes("google")) &&
          product.slug === "gsuite"
        ) {
          // name = getSuiteName(variantSlug);
          product.isOverWritten = true;
        }

        addons.push({
          ...product,
          ...(rtbProduct ? rtbProduct : {}),
          interval: plan.duration.type,
          price: plan.price,
          priceRenewal: plan.priceRenewal,
          shouldShowRenewal: plan.price !== plan.priceRenewal,
          durationValue: plan.duration.amount,
          planId: plan.id,
          plan_id: plan.id,
          isOverWritten: true,
        });
      }
    }
  });

  return addons.filter(product => !!product);
};

/**
 * Map the banditML product slugs to RTB products
 *
 * @param   {Array<String>} Slugs of products to convert in string
 *
 * @return  {Array<Object>} list of addons
 */
export const mapBanditRTBProducts = async decisions => {
  const products = await getProductsFromRTB();

  return decisions
    .map(id => {
      let product = null;

      if (isLocalProduct(id)) {
        product = getProductFromLocal(id);
        product.variant = id;
      } else {
        product = products.find(product => product.variant === id);
      }

      if (isProductWithPlans(id)) {
        product.planId = id;
        product.plan_id = id;
      }

      return product ? product : null;
    })
    .filter(product => !!product);
};

/**
 * Create a product name based on slug
 *
 * @param {string} slug
 * @return {string}
 */
export const getSuiteName = slug => {
  switch (slug) {
    case "gmail-for-custom-domains":
      return "GMail for Custom Domains";
    case "gsuite-email":
      return "G Suite Email";
    case "google-workspace":
      return "Google Workspace";
    case "google-mail-for-your-domain":
      return "Google Mail for your Domain";
    case "google-mail":
      return "Google Mail";
    default:
      return "G Suite";
  }
};

/**
 * Check if product needs request Access
 *
 * @param {string} slug
 */
export const isRequestAccess = slug => {
  return [
    "identity-theft-insurance",
    "business-insurance",
    "local-listing-manager",
    "local-listings",
    "directory-listings",
    "ad-manager-faster",
    "ad-manager-create",
    "easy-ads-maker-faster",
    "easy-ads-maker-create",
    "email-marketing-tool",
  ].includes(slug);
};

/**
 * Check if product is reputation manager
 *
 * @param {string} slug
 */
export const isReputationManager = slug => {
  return reputationManager.includes(slug);
};

/**
 * Check if Insurance product
 *
 * @param {string} slug
 */
export const isInsuranceProduct = slug => {
  return [
    "identity-theft-insurance",
    "business-insurance",
    "identity-theft-insurance-mo",
    "business-insurance-mo",
  ].includes(slug);
};

/**
 * Check if product is listing
 *
 * @param {string} slug
 * @returns
 */
export const isListingProduct = slug => {
  return ["local-listing-manager", "local-listings", "directory-listings"].includes(slug);
};

/**
 * Check if product is ad manager
 *
 * @param {string} slug
 * @returns
 */
export const isAdManagerProduct = slug => {
  return [
    "ad-manager-faster",
    "ad-manager-create",
    "easy-ads-maker-faster",
    "easy-ads-maker-create",
  ].includes(slug);
};

/**
 * Check if the slug is a product that can be added
 * only one time in the cart
 *
 * @param {string} slug
 * @return {boolean}
 */
export const isLimitedInCart = slug => {
  return ["vpn", "social-media-manager", "review-manager", "stencil", "sitelock"].includes(slug);
};

export const parseRTBProductFields = rtbProduct => {
  if (!rtbProduct) return {};

  return {
    domain_less_promo_price: rtbProduct.domain_less_promo_price,
    domain_less_promo_price_interval: rtbProduct.domain_less_promo_price_interval,
    free_trial: rtbProduct.free_trial,
    id: rtbProduct.id,
    interval: rtbProduct.interval,
    name: rtbProduct.name,
    order: rtbProduct.order,
    price: rtbProduct.price,
    priceRenewal: rtbProduct.renewal_price,
    tagline: rtbProduct.tagline,
    tooltip: rtbProduct.tooltip,
  };
};

/**
 * Get the products from RTB Endpoint
 *
 * @return {Array<Products>}
 */
export const getProductsFromRTB = async () => {
  const { products } = await getRTBProducts();

  return (products || []).map(product => {
    return {
      ...product,
      priceRenewal: product.renewal_price,
      slug: RTB_SLUGS[product.id],
    };
  });
};

/**
 * Get a product from Json file
 *
 * @param {string} id
 * @return {Product|null}
 */
export const getProductFromLocal = id => {
  return productsJson.products.find(product => product.id === id);
};

/**
 * Check if ID must use json file
 * @param {string} id
 * @return {boolean}
 */
export const isLocalProduct = id => {
  return ["stencil", "business-card-maker-mo", "sitelock", "seo-manager"].includes(id);
};

/**
 * Check if ID is having plans
 *
 * @param {string} id
 * @return {boolean}
 */
export const isProductWithPlans = id => {
  if (
    id.includes("ewp") ||
    id.includes("easy-wp") ||
    id.includes("stellar") ||
    id.includes("ox") ||
    id.includes("vpn") ||
    id.includes("gsuite") ||
    id.includes("social") ||
    id.includes("review")
  ) {
    return true;
  }

  return false;
};
