import { action, computed, makeObservable, observable, runInAction, _resetGlobalState } from "mobx";

import {
  MODES,
  COOKIE_HNS,
  COOKIE_PICKS,
  COOKIE_PRODUCTS,
  extractStandardQuery,
  createDomain,
  createDomains,
  createPickProduct,
  splitDomain,
  prepareQuery,
  usePremiumEndpoint,
} from "store/Search/utils";
import { getTopAuctions, getAuctions } from "utils/auctions";
import {
  getTlds,
  getFilters,
  getPicks,
  getFeatured,
  getFeaturedSuggestions,
  getHnsTlds,
  getDomainStatus,
} from "utils/backend";
import { getQueryStringParam, isTruthyCookie, setCookie } from "utils/browser";
import { getCart, getCartDetails } from "utils/cart";
import { cleanQuery, isString, parseEmojis } from "utils/format";
import { asyncForEach } from "utils/functions";
import { isLimitedInCart } from "utils/product";
import { query } from "utils/workers";

/**
 * The search store.
 *
 * It's used with React context to share data with any component.
 *
 * @author Gustavo Straube <gustavo@kettle.io>
 */
class Search {
  /**
   * Is Valid Search
   *
   * @type {Boolean}
   */
  isValidSearch = false;

  /**
   * The search error.
   *
   * @type {String}
   */
  error = null;

  /**
   * The qualified (featured) domain name.
   *
   * @type {String}
   */
  qualified = null;

  /**
   * The currenct search query.
   *
   * @type {String}
   */
  query = "";

  /**
   * Beast mode query
   *
   * @type {String}
   */
  beastQuery = "";

  /**
   * The current search query without any change
   *
   * @type {String}
   */
  inputQuery = "";

  /**
   * The current filter.
   *
   * @type {String}
   */
  filter = "";

  /**
   * The list of suggested results (picks).
   *
   * @type {Array}
   */
  picks = [];

  /**
   * The list of results.
   *
   * @type {Array<Domain>}
   */
  results = [];

  /**
   * The list of top suggested auctions
   *
   * @type {Array}
   */
  topAuctions = [];

  /**
   * The list of suggested auctions
   *
   * @type {Array}
   */
  auctions = [];

  /**
   * The featured domain.
   *
   * @type {Domain}
   */
  featured = null;

  /**
   * Featured Suggestion
   *
   * @type {Domain}
   */
  featuredSuggestion = null;

  /**
   * Featured Suggestions list
   *
   * @type {Array<Domain>}
   */
  featuredSuggestions = [];

  /**
   * Is Loading/Waiting for Picks
   *
   * @type {Boolean}
   */
  loadedPicks = false;

  /**
   * Is loading/waiting for featured domain
   *
   * @type {Boolean}
   */
  loadedFeatured = false;

  /**
   * Is loading/waiting for results
   *
   * @type {Boolean}
   */
  loadedResults = false;

  /**
   * auctions are loaded
   *
   * @type {Boolean}
   */
  loadedAuctions = false;

  /**
   * The share ID.
   *
   * @type {String}
   */
  share = null;

  /**
   * The tld Type
   *
   * @type {String}
   */
  tldtype = null;

  /**
   * HNS toggler enabled
   *
   * @type {Boolean}
   */
  hnsEnabled = true;

  /**
   * whether Business Generator is enabled
   *
   * @type {Boolean}
   */
  _generatorEnabled = false;

  /**
   * whether Featured suggestions is enabled
   *
   * @type {Boolean}
   */
  suggestionEnabled = false;

  /**
   * The sort by
   *
   * @type {String}
   */
  sort = "Default";

  /**
   * The beast params.
   *
   * @type {Object}
   */
  beastParams = {};

  /**
   * Current results offset.
   *
   * @type {Number}
   */
  offset = 0;

  /**
   * The latest cart object.
   *
   * @type {Object}
   */
  cart = null;

  /**
   * Toggle if the suggestions are enabled or not
   *
   * @type {Boolean}
   */
  suggestionsEnabled = false;

  /**
   * The search mode.
   *
   * @type {String}
   * @private
   */
  _mode = MODES.standard;

  /**
   * The list of domains that should be checked against Aftermarket.
   *
   * @type {Array<Domain>}
   * @private
   */
  _aftermarketQueue = [];

  /**
   * Indicate whether the suggested results should be hidden.
   *
   * @type {Boolean}
   * @private
   */
  _hidePicks = isTruthyCookie(COOKIE_PICKS);

  /**
   * Indicate whether the product suggestions should be hidden.
   *
   * @type {Boolean}
   * @private
   */
  _hideProducts = isTruthyCookie(COOKIE_PRODUCTS);

  /**
   * Indicate whether the HNS Info should be hidden.
   *
   * @type {Boolean}
   * @private
   */
  _hideHNS = isTruthyCookie(COOKIE_HNS);

  /**
   * Store the picks decision infon
   *
   * @type {Object}
   */
  picksDecision = null;

  /**
   * Store the query connection status
   *
   * @type {Object}
   */
  isQueryConnected = false;

  /**
   * Determine if the ML Suggestions are enabled
   */
  mlSuggestionsEnabled = false;

  /**
   * Create a new instance.
   *
   * @param {String} [query=null]
   */
  constructor(query = null, mode = null, share = null, tldtype = null, sort = null) {
    makeObservable(this, {
      isValidSearch: observable,
      error: observable,
      filter: observable,
      loadedPicks: observable,
      loadedFeatured: observable,
      loadedResults: observable,
      loadedAuctions: observable,
      picks: observable,
      results: observable,
      topAuctions: observable,
      auctions: observable,
      featuredSuggestions: observable,
      featured: observable,
      featuredSuggestion: observable,
      offset: observable,
      picksDecision: observable,
      _hidePicks: observable,
      _hideProducts: observable,
      _hideHNS: observable,
      _mode: observable,
      _generatorEnabled: observable,
      suggestionEnabled: observable,
      inputQuery: observable,
      query: observable,
      qualified: observable,
      beastQuery: observable,
      mlSuggestionsEnabled: observable,

      constructor: action,
      reset: action,
      search: action,
      next: action,
      loadAuctions: action,
      toggleType: action,
      _sortDomains: action,
      _createFeatured: action,
      _createPicks: action,
      _syncCart: action,
      _runQueryFor: action,
      _checkAftermarket: action,
      isDomainInResults: action,
      nextFeaturedSuggestion: action,
      loadFeaturedSuggestions: action,
      changeQuery: action,

      left: computed,
    });

    this._generatorEnabled = true;
    this.suggestionEnabled = false;
    this.mlSuggestionsEnabled = getQueryStringParam("mlsuggestions")
      ? ["true", true].includes(getQueryStringParam("mlsuggestions"))
      : false;

    if (query) {
      this.query = query;
      this.inputQuery = query;
    }
    if (mode === MODES.beast) {
      this.beastQuery = query;
    }
    if (mode && MODES[mode]) {
      this.mode = mode;
    }
    if (share) {
      this.share = share;
    }
    if (tldtype) {
      this.tldtype = tldtype;
    }
    if (sort) {
      this.sort = ["Alphabetical", "TLD", "Price"].includes(sort)
        ? sort
        : mode === "beast"
        ? "Default"
        : "Alphabetical";
    }

    document.addEventListener(
      "shoppingCart.remove.success",
      () => {
        this.syncCart();
      },
      false
    );
  }

  /**
   * The search mode.
   *
   * @return {String}
   */
  get mode() {
    return this._mode;
  }

  /**
   * Set the search mode.
   *
   * @param  {String} mode
   * @return {void}
   */
  set mode(mode) {
    if (mode === this._mode || !MODES[mode]) {
      return;
    }

    const batch = mode === MODES.beast;

    if (this.isQueryConnected) {
      // Close any active connection
      this.closeQuery();
    }

    this._mode = mode;

    // create a new WS connection only if needed (beast mode or standar mode)
    if (mode === MODES.beast || mode === MODES.standard) {
      query.conn(batch);
      this.isQueryConnected = true;
    } else {
      this.isQueryConnected = false;
    }
  }

  /**
   * The number of domains left in the results considering the current offset.
   *
   * @return {Number}
   */
  get left() {
    if (!this.results) {
      return 0;
    }
    return this.results.length - this.offset;
  }

  /**
   * Indicate whether the suggested results should be hidden.
   *
   * @return {Boolean}
   */
  get isPicksHidden() {
    return this._hidePicks;
  }

  /**
   * Indicate whether the business generator should be shown.
   *
   * @return {Boolean}
   */
  get isGeneratorEnabled() {
    return this._generatorEnabled;
  }

  /**
   * Indicate whether the product suggestions should be hidden.
   *
   * @return {Boolean}
   */
  get isProductsHidden() {
    return this._hideProducts;
  }

  /**
   * Indicate whether the HNS Modal Info should be hidden.
   *
   * @return {Boolean}
   */
  get isHNSHidden() {
    return this._hideHNS;
  }

  /**
   * Indicates if the HNS toggler should be shown or hidden.
   *
   * @return {Boolean}
   */
  get isHNSEnabled() {
    return this.hnsEnabled;
  }

  /**
   * Check whether the current mode is the standard.
   *
   * @return {Boolean}
   */
  isStandard() {
    return this.mode === MODES.standard;
  }

  /**
   * Set the current mode to standard.
   *
   * @return {void}
   */
  setStandard() {
    this.mode = MODES.standard;
  }

  /**
   * Check whether the current mode is the beast.
   *
   * @return {Boolean}
   */
  isBeast() {
    return this.mode === MODES.beast;
  }

  /**
   * Set the current mode to beast.
   *
   * @return {void}
   */
  setBeast() {
    this.mode = MODES.beast;
  }

  /**
   * Check whether the current mode is handshake.
   *
   * @return {Boolean}
   */
  isHNS() {
    return this.mode === MODES.hns;
  }

  /**
   * Set the current mode to Handshake domains.
   *
   * @return {void}
   */
  setHNS() {
    this.mode = MODES.hns;
  }

  /**
   * Check whether the current mode is auctions.
   *
   * @return {Boolean}
   */
  isAuctions() {
    return this.mode === MODES.auctions;
  }

  /**
   * Set the current mode to Auctions.
   *
   * @return {void}
   */
  setAuctions() {
    this.mode = MODES.auctions;
  }

  /**
   * Check whether the current mode is generator.
   *
   * @return {Boolean}
   */
  isGenerator() {
    return this.mode === MODES.generator;
  }

  /**
   * Set the current mode to Generator.
   *
   * @return {void}
   */
  setGenerator() {
    this.mode = MODES.generator;
  }

  /**
   * Close the current WS connection
   *
   * @return {void}
   */
  closeQuery() {
    query.close();
  }

  toggleType(type = MODES.standard) {
    if (type === this.mode) {
      return;
    }

    if (type === MODES.beast) {
      this.setBeast();
      this.loadedResults = false;
      this.results = [];
    }

    if (type === MODES.auctions) {
      this.setAuctions();
      this.loadAuctions();
    }

    if (type === MODES.hns) {
      this.setHNS();
      this.results = [];
      this.loadedResults = false;
      this.search();
    }

    if (type === MODES.generator) {
      this.setGenerator();
    }

    if (type === MODES.standard) {
      this.setStandard();
      this.loadedResults = false;
      this.results = [];
      this.search();
    }
  }

  /**
   * Reset the search state.
   *
   * The optional parameter indicates if this is a query to filter current
   * results.
   *
   * @return {void}
   */
  reset(filtering = false) {
    this._aftermarketQueue = [];
    this.error = null;
    this.offset = 0;

    if (!filtering) {
      this.qualified = null;
      this.picks = [];
      this.topAuctions = [];
      this.auctions = [];
      this.featured = null;
      this.loadedPicks = false;
      this.loadedFeatured = false;
      this.loadedResults = false;
      this.featuredSuggestion = null;
      this.featuredSuggestions = [];
    }
  }

  /**
   * Get visible results.
   *
   * Visible results are domains that should appear in the search results
   * list.
   *
   * @param  {Currency} currency
   * @return {Array<Domain>}
   */
  getVisibleResults(currency) {
    return this.results.filter(
      domain =>
        domain &&
        !isString(domain) &&
        !domain.hidden &&
        domain.shouldShow(this.beastParams, currency) &&
        !this.isFeaturedDomain(domain)
    );
  }

  /**
   * Check if a domain is in the current search results.
   *
   * @param {Domain} domain
   */
  isDomainInResults(domain) {
    return !!this._findDomainByName(domain.id);
  }

  /**
   * Change the current query value and input value
   *
   * @param {String} value
   */
  changeQuery(value) {
    this.query = value;
    this.inputQuery = value;
  }

  /**
   * Run the search.
   *
   * The optional parameter indicates if this is a query to filter current
   * results. This is useful, for instance, to avoid loading picks for the
   * same query more than one time.
   *
   * @param  {Boolean} [filtering=false]
   * @return {Promise}
   */
  async search(filtering = false) {
    if (this.isAuctions() || this.isGenerator()) {
      return;
    }

    this.reset(filtering);

    let query = this.isBeast() ? this.beastQuery : this.query;
    let qualified = null;

    if (this.isStandard() || this.isHNS()) {
      try {
        [query, qualified] = await extractStandardQuery(query, this.isHNS());
      } catch (e) {
        this.error = e.message;
        if (!this.error.match(/TLD$/i)) {
          return;
        }
        [query] = splitDomain(prepareQuery(query));
      }
    } else {
      const queries = query.split(/[\r\n\t, ]/);
      query = queries.join("\n");

      if (queries.length === 1) {
        try {
          this.error = null;
          await extractStandardQuery(query, this.isHNS());
        } catch (e) {
          this.error = e.message;
          if (!this.error.match(/TLD$/i)) {
            return;
          }
        }
      }
    }

    if (!query) {
      this.isValidSearch = false;
      this.loadedResults = false;
      this.loadedPicks = false;
      this.results = [];
      this.picks = [];
      return;
    }

    runInAction(() => {
      if (this.isBeast()) {
        this.beastQuery = query;
      } else {
        this.query = query;
      }

      this.isValidSearch = true;
      this.qualified = qualified;
      this.loadedResults = false;
      this.loadedPicks = false;
      this.results = [];
      this.featuredSuggestions = [];
      this.featuredSuggestion = null;
    });

    const tlds = await this._getCurrentTlds();
    const domains = this.isHNS()
      ? await this._getHnsDomains(query, tlds)
      : await createDomains(query, this.isStandard() ? tlds : []).catch(console.error);

    runInAction(() => {
      this.loadedResults = true;
      this.results = domains;
    });

    if (this.isStandard() || this.isHNS()) {
      this._sortDomains();

      const promises = [this._createFeatured()];

      if (this.suggestionEnabled && !this.qualified && !this.isHNS()) {
        promises.push(this._createFeaturedSuggestion());
      }

      let [featured, suggestion = null] = await Promise.all(promises);

      runInAction(() => {
        this.loadedFeatured = true;
        this.featured = featured;
        this.featuredSuggestion = suggestion;
      });
    }

    if (!filtering && !this.tldtype && this.isStandard() && !this.isHNS()) {
      const picks = await this._createPicks();
      runInAction(() => {
        this.picks = picks;
        this.loadedPicks = true;
      });
    }

    if (this.isBeast() || this.isHNS()) {
      runInAction(() => {
        this.loadedPicks = true;
        this.loadedFeatured = true;
      });
    }

    await this.loadCart();
    this._syncCart();
    this._runQuery();
  }

  /**
   * Run the auctions search
   *
   */
  async loadAuctions() {
    const topAuctions = await getTopAuctions();
    const auctions = await getAuctions(this.query);

    runInAction(() => {
      this.loadedAuctions = true;
      this.topAuctions = topAuctions.length > 0 ? topAuctions : [];
      this.auctions = auctions.length > 0 ? auctions : [];
    });
  }

  /**
   * Load the next page of results when on beast mode.
   *
   * @return {Promise}
   */
  async next() {
    if ((this.isBeast() || this.isHNS()) && this.offset < this.results.length) {
      await this._runQuery();
      await this._syncCart();
    }
  }

  /**
   * Load cart from API
   *
   * @return {Promise}
   */
  async loadCart() {
    const cartLoader = this.mlSuggestionsEnabled ? getCartDetails : getCart;
    this.cart = await cartLoader();
  }

  /**
   * Synchronize domains and products with cart status.
   *
   * @return {Promise}
   */
  async syncCart() {
    const cartLoader = this.mlSuggestionsEnabled ? getCartDetails : getCart;
    this.cart = await cartLoader();
    this._syncCart();
  }

  /**
   * Refresh the cart sidebar.
   *
   * @return {void}
   */
  refreshCart() {
    !!window.Reload && window.Reload(); // NC function to reload the sidebar cart
  }

  /**
   * Hide the suggested results.
   *
   * @return {void}
   */
  hidePicks() {
    setCookie(COOKIE_PICKS, true);
    this._hidePicks = true;
  }

  /**
   * Hide the product suggestions.
   *
   * @return {void}
   */
  hideProducts() {
    setCookie(COOKIE_PRODUCTS, true);
    this._hideProducts = true;
  }

  /**
   * Hide the hns modal info suggestions.
   *
   * @return {void}
   */
  hideHNS() {
    setCookie(COOKIE_HNS, true);
    this._hideHNS = true;
  }

  /**
   * Change the active featured suggestion
   *
   * @return {boolean}
   */
  nextFeaturedSuggestion() {
    if (this.featuredSuggestions.length > 0) {
      const _suggestions = this.featuredSuggestions.filter(domain => domain.canAddToCart());
      const suggestion = _suggestions.shift();

      runInAction(() => {
        this.featuredSuggestions = _suggestions;
        this.featuredSuggestion = suggestion;
      });

      return _suggestions.length > 0;
    }

    return false;
  }

  /**
   * Manually load featured suggestions
   *
   * @return {void}
   */
  async loadFeaturedSuggestions() {
    const suggestion = await this._createFeaturedSuggestion();

    runInAction(() => {
      this.featuredSuggestion = suggestion;
    });
  }

  /**
   * Check if the featured suggestions array is not empty
   * or has available domains
   *
   * @return {boolean}
   */
  hasAvailableFeaturedSuggestion() {
    if (this.featuredSuggestions.length > 0) {
      return this.featuredSuggestions.filter(domain => domain.canAddToCart()).length > 0;
    }

    return false;
  }

  /**
   * Check if a domain is featured domain
   *
   * @param {Domain} domain
   * @return {Boolean}
   */
  isFeaturedDomain(domain) {
    if (!this.featured) {
      return false;
    }

    if (isString(domain)) {
      return this.featured.id === domain;
    }

    return this.featured.id === domain.id;
  }

  /**
   * Run the query to update domains.
   *
   * @param {Array<Domain>} results = []
   * @return {Promise}
   * @private
   */
  async _runQuery(results = []) {
    const domains =
      this.isBeast() || this.isHNS()
        ? await this._nextResults()
        : results.length > 0
        ? results
        : this.results;
    const domainNames = domains.filter(domain => !!domain).map(domain => domain.id);
    await query.status(domainNames, data => {
      const domain = this._findDomainByName(data.name);
      if (!domain) {
        return;
      }

      this._checkStatus(domain, data);
    });

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          if (this._aftermarketQueue.length === 0) {
            return resolve();
          }

          const domains = this._aftermarketQueue.splice(0);
          return resolve(
            query.aftermarket(domains, data => {
              const domain = this._findDomainByName(data.domain);
              domain && this._checkAftermarket(domain, data);
            })
          );
        } catch (error) {
          reject(error);
        }
      }, 250);
    });
  }

  /**
   *
   * @param {*} domain
   */
  async _runQueryFor(domain, aftermarket = null) {
    await query.status([domain.id], data => {
      this._checkStatus(domain, data, aftermarket);
    });
  }

  /**
   * Load the next results, creating the required domain objects.
   *
   * @return {Promise<Array<Domain>>}
   */
  async _nextResults() {
    const offset = this.offset;
    const offsetVal = this.isBeast() ? 100 : 20;
    const domains = await Promise.all(
      this.results.slice(this.offset, (this.offset += offsetVal)).map(async domain => {
        const [sld, tld] = splitDomain(domain);
        return await createDomain(sld, tld);
      })
    );
    this.results.splice(offset, offsetVal, ...domains);
    return domains;
  }

  /**
   * Based on the latest cart object, synchronize domains and products with
   * cart status.
   *
   * @return {Promise}
   */
  _syncCart() {
    const cart = this.cart;
    const useMlSuggestions = this.mlSuggestionsEnabled;

    this.results.forEach(domain => {
      if (!domain || isString(domain)) {
        return;
      }

      const item = cart.domains.find(item => item.domain === domain.id);
      if (item) {
        domain.setPurchased();
      } else if (domain.isPurchased()) {
        domain.setReady();
      }

      domain.products = cart.products
        .filter(item => item.domain === domain.id || isLimitedInCart(item.id))
        .map(item => {
          if (useMlSuggestions) {
            return item.variant || item.id;
          }

          if (item.id.match(/^stellar-/)) {
            return "stellar";
          }
          if (item.id.match(/^positivessl-/) || item.id === "ev-ssl") {
            return "positivessl";
          }
          return item.id;
        });
    });
  }

  /**
   * Get the list of TLDs related to the current filter.
   *
   * @return {Promise<Array<Object>>}
   * @private
   */
  async _getCurrentTlds() {
    const filters = await getFilters();
    if (!this.filter && !this.tldtype) {
      this.filter = Object.keys(filters)[0];
    }

    if ((this.tldtype || "").toLowerCase().trim() === "international") {
      this.filter = "International ";
    }

    const tlds = await getTlds();

    const current = [];

    if (this.qualified) {
      const tld = await this._findTld(
        this.qualified.substring(this.qualified.indexOf(".") + 1),
        tlds
      );
      if (tld) {
        tld.Sequence = -999;
        current.push(tld);
      }
    }

    if (this.isHNS()) {
      const hnsTlds = await getHnsTlds();
      const _tlds = tlds.filter(tld => hnsTlds.includes(tld.Name));

      return current
        .concat(_tlds)
        .filter((tld, index, arr) => {
          if (!tld) {
            return false;
          }

          return arr.findIndex(item => item && item.Name === tld.Name) === index;
        })
        .sort((a, b) => hnsTlds.indexOf(a.Name) - hnsTlds.indexOf(b.Name));
    }

    // if there is a tldtype filter and is not filtering by category it will return the domains with the tld type
    if (this.tldtype && !this.filter) {
      return current.concat(
        tlds.filter(tld => tld.Type.toLowerCase() === this.tldtype.toLocaleLowerCase())
      );
    }

    return current
      .concat(
        await Promise.all(
          filters[this.filter].map(async (name, index) => {
            const tld = await this._findTld(name, tlds);
            if (!tld) {
              return null;
            }
            tld.Sequence = index;
            return tld;
          })
        )
      )
      .filter((tld, index, arr) => {
        if (!tld) {
          return false;
        }

        return arr.findIndex(item => item && item.Name === tld.Name) === index;
      })
      .filter(tld => {
        if (this.tldtype && !["international"].includes(this.tldtype)) {
          return tld.Type.toLowerCase() === this.tldtype.toLocaleLowerCase();
        }

        return true;
      });
  }

  /**
   * Get the list of available domains based on HNS TLDs
   * @param {string} query
   * @param {Array} tlds
   */
  async _getHnsDomains(query, tlds) {
    const domains = tlds.map(tld => `${query}.${tld.Name}`);
    return domains;
  }

  /**
   * Sorts the current results.
   *
   * A side-effect of this method is setting the featured domain if one of the
   * results matches the current query (qualified domain).
   *
   * @return {void}
   */
  _sortDomains() {
    let featured = null;
    const results = this.results.slice(0).sort((a, b) => {
      if (a.id === this.qualified) {
        featured = a;
        a.sequence = -999;
      }
      if (b.id === this.qualified) {
        featured = b;
        b.sequence = -999;
      }
      return a.sequence - b.sequence;
    });
    if (featured) {
      this.featured = featured;
    }

    // This is an observableArray from mobx, that's why we need to use this
    // workaround to sort & update it in place.
    this.results.replace(results);
  }

  /**
   * Create the list of suggested results (picks).
   *
   * @return {Promise<Array<Domain|Object>>}
   */
  async _createPicks() {
    const picks = await getPicks(this.qualified ? this.qualified : this.query);
    const results = (
      await Promise.all(
        picks.map(async pick => {
          if (pick.type === "product") {
            return createPickProduct(pick);
          }

          let name = pick.domain || pick.aftermarket.domain || pick.status.name;
          if (!name) {
            return null;
          }
          name = cleanQuery(name);

          const [sld] = splitDomain(name);
          const tld = (pick.tld || pick.name).toLowerCase();

          let domain = this._findDomain(sld, tld);
          const exists = !!domain;

          if (!exists) {
            domain = await createDomain(sld, tld, true);

            if (!domain) {
              return null;
            }

            runInAction(() => {
              this.results.push(domain);
            });
          }

          if (pick.status) {
            this._checkStatus(domain, pick.status, pick.aftermarket);
          }
          return domain;
        })
      )
    )
      .filter(pick => !!pick)
      .sort((a, b) => {
        return ((a.info && a.info.order) || 0) - ((b.info && b.info.order) || 0);
      });

    return results;
  }

  /**
   * Create the featured domain.
   *
   * The featured domain is based on the RTB API match.
   *
   * @return {Promise<Object>}
   */
  async _createFeatured() {
    if (this.isBeast()) {
      return null;
    }

    let featured = await getFeatured(this.qualified || this.query);

    if (featured) {
      featured.tld = parseEmojis(featured.tld);
    }

    if (featured.tld === "com" && this.isHNS()) {
      featured = {
        tld: "p",
        domain: featured.domain.replace(".com", ".p"),
      };
    }

    /*
     * Sometimes, due to the async nature of this function, the featured
     * domain is loaded when the query already changed (quick type changes).
     * For those cases, we have to check if featured is set (in case of a
     * FQDN was typed in) and return it.
     */
    if (this.featured) {
      // only return the featured domain SLD if it matches the current query (without TLD)
      if (this.featured.sld === this.query) {
        return this.featured;
      }
    }

    const name = cleanQuery(featured.domain);
    let domain = this._findDomainByName(name);

    if (!domain) {
      const tld = await this._findTld(featured.tld.toLowerCase());
      if (!tld) {
        return null;
      }

      const [sld] = splitDomain(name);
      domain = await createDomain(sld, tld, true);
    }

    const { status, aftermarket } = await getDomainStatus(domain.id, this.isHNS() ? false : true);
    this._checkStatus(domain, status, aftermarket);
    return domain;
  }

  /**
   * load a featured suggestions based on new namecheapapi endpoint
   * If the featured domain is unavailable, it will use this suggestion
   */
  async _createFeaturedSuggestion() {
    if (this.isBeast()) {
      return null;
    }

    let _returnSuggestion = null;
    const suggestions = await getFeaturedSuggestions(this.qualified || this.query);

    if (!suggestions) {
      return null;
    }

    await asyncForEach(suggestions, async (suggestion, index) => {
      const name = cleanQuery(suggestion.domain);
      let domain = this._findDomainByName(name);

      if (!domain) {
        const tld = await this._findTld(suggestion.tld.toLowerCase());
        if (!tld) {
          return null;
        }

        const [sld] = splitDomain(name);
        domain = await createDomain(sld, tld, true);
        domain.data.aftermarketPreloaded = true;
        domain.isFeaturedSuggestion = true;
      }

      if (!this.isHNS()) {
        this._checkAftermarket(domain, suggestion.aftermarket);
      }

      if (_returnSuggestion === null) {
        _returnSuggestion = domain;
      }

      runInAction(() => {
        if (index > 0) {
          this.featuredSuggestions.push(domain);
        }
      });
    });

    return _returnSuggestion;
  }

  /**
   * Check the domain status based on the given data (usually a webservice
   * response).
   *
   * Optionally, this function can also handle the data from Aftermarket.
   *
   * @param  {Domain} domain
   * @param  {Object} data
   * @param  {Object} [aftermarket=null]
   * @return {void}
   */
  _checkStatus(domain, data, aftermarket = null) {
    if (!data) {
      domain.error = "Unable to load domain";
      return;
    }

    const { available, premium, ...extra } = data;

    if (domain.data.status) {
      return;
    }

    domain.data.status = data;

    if (extra.fee) {
      domain.data.extra = extra;
    }

    const _pushToAftermarket = async () => {
      if (this.isHNS()) {
        domain.setUnavailable();
        return;
      }

      const _usePremiumEndpoint = await usePremiumEndpoint(domain.tld);

      if (available && premium && extra.lookupType === "EPP" && extra.fee && !_usePremiumEndpoint) {
        if (extra.fee.retailAmount) {
          domain.setPremium("namecheap", {
            price: extra.fee.retailAmount,
            ...(extra.renewalFee ? { renewal: extra.renewalFee.retailAmount } : {}),
          });

          return;
        }
      }

      if (domain.data.aftermarketPreloaded) {
        return this._checkAftermarket(domain, domain.data.aftermarketPreloaded);
      }

      if (aftermarket) {
        return this._checkAftermarket(domain, aftermarket);
      }

      this._aftermarketQueue.push(domain.id);
    };

    domain.setStatusLoaded();
    if (domain.id === "plus.io") {
      return domain.setUnavailable();
    }

    if (available) {
      if (!premium) {
        return domain.setAvailable();
      }

      if (domain.id.match(/\.de$/i)) {
        return domain.setUnavailable();
      }

      domain.setPremium();
      return _pushToAftermarket(domain);
    }

    _pushToAftermarket(domain);
  }

  /**
   * Check the domain status based on the data returned from Aftermarket API.
   *
   * @param  {Domain} domain
   * @param  {Object} data
   * @return {void}
   */
  _checkAftermarket(domain, data) {
    const { price, first_month_payment, status, username, type, namecheap_id, fast_transfer } =
      data;

    if (domain.data.aftermarket) {
      return;
    }

    domain.data.aftermarket = data;

    if (status === "error") {
      domain.error = "Unable to load domain";
      return;
    }

    if (domain.isPremium() && (status !== "active" || username !== "financed")) {
      return query
        .premium(domain.id, data => {
          if (!data || !data.price) {
            return domain.setUnavailable();
          }

          domain.data.premium = data;
          domain.setPremium(data.registry, {
            price: data.price,
            renewal: data.renewal_price,
          });
        })
        .catch(console.error);
    }

    if (status !== "active") {
      return domain.setUnavailable();
    }

    const extra = domain.data.extra || {};
    if (extra.renewalFee) {
      domain.setPrice({
        renewal: extra.renewalFee.retailAmount,
      });
    } else if (!domain.data.aftermarketPreloaded) {
      // Check if domain has a premium renewal price
      query
        .premium(domain.id, data => {
          if (!data || !data.renewal_price) {
            return;
          }
          const price = {
            renewal: data.renewal_price,
          };

          /*
           * Add premium renewal price to domain price, because when a domain
           * is transferred into NC from Sedo the domain is also renewed for
           * the customer for one year.
           */
          if (username === "sedo" && type === "buynow" && fast_transfer) {
            price.price = domain.price.price + data.renewal_price;
          }

          domain.setPrice(price);
        })
        .catch(console.error);
    }

    if (type === "buynow" && ["sedo", "afternic"].includes(username)) {
      return domain.setPremium(username, {
        price: price,
      });
    }

    if (type === "offer" && ["sedo", "afternic", "gmo"].includes(username)) {
      return domain.setOffer(username, {
        price: username === "sedo" ? price : 0,
      });
    }

    if (domain.id.match(/\.de$/i)) {
      return domain.setUnavailable();
    }

    if (username === "financed") {
      return domain.setPremium(username, {
        price: first_month_payment,
      });
    }

    if (username === "namecheap") {
      domain.setPremium(username, {
        price: price,
      });
      namecheap_id && (domain.namecheapId = namecheap_id);
      return;
    }

    if (type === "platinum") {
      return domain.setPlatinum(username);
    }

    domain.setUnavailable();
  }

  /**
   * Find a domain.
   *
   * @param  {String} sld
   * @param  {String} tld
   * @return {Domain}
   */
  _findDomain(sld, tld) {
    const d = this.results.find(domain => {
      return domain && domain.sld === sld && domain.tld === tld;
    });
    if (!d && this.featured && this.featured.sld === sld && this.featured.tld === tld) {
      return this.featured;
    }
    return d;
  }

  /**
   * Find a domain by its full name (SLD + TLD).
   *
   * @param  {String} name
   * @return {Domain}
   */
  _findDomainByName(name) {
    return this.results.find(domain => {
      return domain && domain.id === name;
    });
  }

  /**
   * Find a TLD by its name.
   *
   * You can optionally pass a list of TLDs to search in.
   *
   * @param  {String} name
   * @param  {Array<Object>} [tlds=null]
   * @return {Promise<Object>}
   */
  async _findTld(name, tlds = null) {
    tlds = tlds || (await getTlds());
    return tlds.find(tld => {
      return tld.Name === name;
    });
  }
}

export default Search;
