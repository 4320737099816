import axios from "utils/axios";
import { getRank, isValidIp, getRTBProducts } from "utils/backend";
import {
  getBrowserLang,
  getBrowserType,
  getCookie,
  getOSInfo,
  getQueryStringParam,
} from "utils/browser";
import { isAuthenticated } from "utils/user";
import { getRefId, getCartDetails, getCartSummary } from "utils/cart";
import { asyncForEach } from "utils/functions";
import {
  getProductsFromRTB,
  getPlans,
  isInsuranceProduct,
  isAdManagerProduct,
  isLocalProduct,
  getProductFromLocal,
} from "utils/product";

/**
 * The API key for Bandit key.
 *
 * @type {String}
 */
const banditKey = process.env.REACT_APP_BANDIT_KEY;

/**
 * The experiment ID to use with Bandit.
 *
 * @type {String}
 */
const banditExperiment = process.env.REACT_APP_BANDIT_EXPERIMENT;

/**
 * The experiment ID to use with Bandit Picks.
 *
 * @type {String}
 */
const picksExperiment = process.env.REACT_APP_BANDIT_PICKS_EXPERIMENT;

/**
 * The static response based on bandit responses.
 *
 * @type {String}
 */
const banditEmulator = process.env.REACT_APP_BANDIT_STATIC_DECISION;

/**
 * The Bandit instance.
 *
 * @type {banditml.BanditAPI}
 */
let _bandit;

/**
 * The Bandit instance 2.
 *
 * @type {banditml.BanditAPI}
 */
let _banditPicks;

/**
 * Validate if test mode is enabled (to use console log).
 *
 * @type {boolean}
 */
const _enabledDebug = Boolean(getCookie("search-enable-test"));

/**
 * Validate if dev products are enabled.
 *
 * @type {boolean}
 */
const _enabledTestProducts = Boolean(getQueryStringParam("test-products"));

/**
 * Variants that use _filterRecomendations
 *
 * @type {Array<String>}
 */
export const variantRecs = [
  "bandit",
  "bandit-4",
  "bandit-6",
  "bandit-top-6",
  "thompson-sampling",
  "thompson-sampling-4",
  "thompson-sampling-6",
  "thompson-sampling-top-6",
  "thompson-sampling-6-plan-selector",
  "thompson-sampling-6-social-media-manager",
  "thompson-sampling-6-easy-social",
  "thompson-sampling-6-grow-with-social",
  "thompson-sampling-6-manage-your-social",
  "gsuite",
  "gmail-for-custom-domains",
  "gsuite-email",
  "google-workspace",
  "google-mail-for-your-domain",
  "google-mail",
  "review-manager",
  "reputation-manager",
  "manage-your-reputation",
  "manage-your-business-reviews",
  "identity-theft-insurance",
  "business-insurance",
  "local-listing-manager",
  "local-listings",
  "directory-listings",
  "ad-manager-faster",
  "ad-manager-create",
  "easy-ads-maker-faster",
  "easy-ads-maker-create",
  "socia-media-manager",
  "grow-your-social",
];

export const reputationManager = [
  "review-manager",
  "reputation-manager",
  "manage-your-reputation",
  "manage-your-business-reviews",
];

export const _debug = (...rest) => {
  if (_enabledDebug) {
    console.log(...rest);
  }
};

/**
 * Get the current Bandit instance.
 *
 * @return {Promise<banditml.BanditAPI>}
 */
const _getBandit = async () => {
  const forcedVariantSlug = getQueryStringParam("variantSlug");
  const _refId = await getRefId();

  _debug("forcedVariantSlug", forcedVariantSlug);

  if (!_bandit) {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (window.banditml) {
        break;
      }
      await new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 250);
      });
    }
    const banditml = window.banditml;
    _bandit = new banditml.BanditAPI(
      banditKey,
      {
        [banditExperiment]: "bandit-ml-wrapper",
      },
      {
        debugMode: _enabledDebug,
        banditHostUrl: process.env.REACT_APP_BANDIT,
        getSessionId: () => _refId,
        ...(forcedVariantSlug ? { debugOptions: { forceVariantSlug: forcedVariantSlug } } : {}),
      }
    );
  }
  return _bandit;
};

/**
 * Get a secondary Bandit instance to use in Picks
 *
 * @return {Promise<banditml.BanditAPI>}
 */
const _getBandit2 = async () => {
  const forcedVariantSlug = getQueryStringParam("picksVariant");
  const _refId = await getRefId();

  if (!_banditPicks) {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (window.banditml) {
        break;
      }
      await new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 250);
      });
    }
    const banditml = window.banditml;
    _banditPicks = new banditml.BanditAPI(
      banditKey,
      {
        [picksExperiment]: "nc-search-picks",
      },
      {
        debugMode: false,
        banditHostUrl: process.env.REACT_APP_BANDIT,
        getSessionId: () => _refId,
        ...(forcedVariantSlug ? { debugOptions: { forceVariantSlug: forcedVariantSlug } } : {}),
      }
    );
  }
  return _banditPicks;
};

/**
 * Filter BanditML decisions to use only the available products/plans
 *
 * @param {String} variantSlug
 * @param {Array<String>} decisions
 * @param {Array<Object>} addons
 */
const _filterRecomendations = async (variantSlug, decisions, addons) => {
  const ids = [];
  const _isValidIp = await isValidIp();
  const _isGSuteVariant =
    variantSlug.includes("gmail") ||
    variantSlug.includes("gsuite") ||
    variantSlug.includes("google");
  const _isListingVariant = variantSlug.includes("-listing");

  await asyncForEach(decisions, async decision => {
    let id = decision;

    if (id.includes("gsuite") && (!_isValidIp || !_isGSuteVariant)) {
      return;
    }

    if (
      isInsuranceProduct(id) &&
      (ids.includes("identity-theft-insurance") || ids.includes("business-insurance"))
    ) {
      return;
    }

    if (_isListingVariant && decision === variantSlug) {
      id = decision + "-mo";
    }

    if (isAdManagerProduct(decision)) {
      id = decision + "-mo";
    }

    if (id === "premium-dns") {
      id = "premium-dns-yr";
    }

    if (isAdManagerProduct(decision) && (ids.includes(variantSlug) || decision !== variantSlug)) {
      return;
    }

    // ignore sitemaker
    if (id.includes("sitemaker")) {
      return;
    }

    if (decision === "social-media-manager" || decision === "grow-your-social") {
      id = decision + "-mo";
    }

    if (decision === "identity-theft-insurance" || decision === "business-insurance") {
      id = variantSlug + "-mo";

      if (variantSlug !== decision) {
        return;
      }
    }

    let parts = id.split("-"),
      productId = null,
      planId = null,
      interval = null,
      plans = { list: [] };
    interval = parts.pop();

    if (id.includes("easy-wp") || id.includes("stellar") || id.includes("ox")) {
      if (parts.length > 1) {
        planId = parts.pop();
      }
    }

    productId = parts.join("-");
    let _firstPart = parts[0];
    if (productId.includes("easy-wp")) {
      _firstPart = "ewp";
    }

    switch (_firstPart) {
      case "ewp":
        plans = await getPlans("easy-wp");
        break;
      case "stellar":
        plans = await getPlans("stellar");
        break;
      case "ox":
        plans = await getPlans("ox-starter");
        break;
      case "gsuite":
        plans = await getPlans("gsuite");
        break;
      case "vpn":
        plans = await getPlans("vpn");
        break;
      default: {
        const product = addons.find(
          product => product.slug === productId && product.interval === interval
        );
        if (product && !ids.find(id => id.includes(productId))) {
          ids.push(decision);
        }
        return;
      }
    }

    if (plans && plans.list) {
      const plan = plans.list
        .filter(plan => plan.id === (planId ? `${productId}-${planId}` : productId))
        .find(plan => plan.duration.type === interval);

      if (plan && !ids.find(id => id.includes(productId))) {
        ids.push(decision);
      }
    }
  });

  if (ids.includes("social-media")) {
    let index = ids.findIndex(id => id === "social-media");
    ids.splice(index, 1);
    ids.unshift("social-media");
  }

  if (isAdManagerProduct(variantSlug)) {
    let index = ids.findIndex(id => variantSlug === id);
    const slug = `${ids[index]}`;
    ids.splice(index, 1);
    ids.push(slug);
  }

  return ids.filter(id => !!id);
};

const _filterRTBRecomendations = async (decisions, limit) => {
  const products = await getProductsFromRTB();
  const ids = decisions.map(decision => {
    if (isLocalProduct(decision)) {
      return decision;
    }

    if (decision.includes("easy-wp")) {
      return decision.replace("easy-wp", "ewp");
    }

    if (decision.includes("ssl")) {
      return "positive-ssl";
    }

    if (decision.includes("ox")) {
      return decision.replace("-mo", "").replace("-yr", "");
    }

    return decision;
  });

  const addons = ids.map(id => {
    let product = null;
    if (isLocalProduct(id)) {
      product = getProductFromLocal(id);
      product.variant = id;
    } else {
      product = products.find(product => product.variant === id);
    }
    return product ? product : null;
  });

  return addons
    .filter(addon => !!addon)
    .filter((product, index, list) => index === list.findIndex(p => p.id === product.id))
    .slice(0, limit)
    .map(product => product.variant);
};

/**
 * Check if the variant should include 6 items instead of 4
 *
 * @param {string} variantSlug
 */
const _isVariantTop6 = variantSlug => {
  return (
    variantSlug.endsWith("top-6") ||
    variantSlug.endsWith("-6") ||
    variantSlug.includes("-6") ||
    variantSlug.includes("gmail") ||
    variantSlug.includes("gsuite") ||
    variantSlug.includes("google") ||
    variantSlug === "variant-41-plus" ||
    reputationManager.indexOf(variantSlug) >= 0 ||
    variantSlug.includes("-insurance") ||
    variantSlug.includes("-listing") ||
    variantSlug.includes("ad-manager") ||
    variantSlug.includes("easy-ads-maker") ||
    variantSlug.includes("social-media-manager") ||
    variantSlug.includes("grow-your-social")
  );
};

/**
 * Get an experiment variant.
 *
 * @param {Object} context
 * @param {Array} addons
 * @param {Boolean} hasDomain
 * @return {Promise<String>}
 */
const _getDecision = async (_context, _addons, _hasDomain) => {
  try {
    const decision = await new Promise(resolve => {
      (async () => {
        let response = {};
        try {
          const { data } = await axios.get(banditEmulator);
          const { decision, id } = data;
          const { variantSlug, variantId, ids: decisions } = decision;
          decisions.push("social-pro");
          // decisions.push("sitelock");

          if (_enabledTestProducts) {
            decisions.push("stencil-mo");
            decisions.push("email-marketing-tool-mo");
          }

          if (!variantSlug) return decisions;

          let ids = [];

          ids = await _filterRTBRecomendations(decisions, 8);

          response.decision = {
            id,
            variantSlug,
            variantId,
            ids,
          };
        } catch (error) {
          console.error(error);
        }

        if (response.decision) {
          resolve(response.decision);
        }

        resolve(null);
      })();
    });

    if (decision) {
      return decision;
    }
  } catch (error) {
    return null;
  }
  return null;
};

const _getPicks = async () => {
  const bandit = await _getBandit2();
  const shouldLogDecision = false;

  try {
    const decision = await new Promise(resolve => {
      (async () => {
        const response = await bandit.getDecision(
          picksExperiment,
          () => [],
          decisions => decisions,
          null,
          shouldLogDecision
        );

        if (response.decision) {
          resolve(response.decision);
          return;
        }

        resolve(null);
      })();
    });

    if (decision) {
      return decision;
    }
  } catch (error) {
    return null;
  }
};

/**
 * Get the bandit context
 *
 * @param {Boolean} beastMode
 * @return {Promise<Object>}
 */
const _getContext = async beastMode => {
  const { domains, products } = await getCartDetails();
  const summary = await getCartSummary();
  const qualities = await getGrossQuality(domains);
  const premiumDomains = domains.filter(domain => domain.premium);
  const sedoPremiumDomains = domains.filter(domain => domain.seller === "sedo");
  const afternicPremiumDomains = domains.filter(domain => domain.seller === "afternic");
  const namecheapDomains = domains.filter(domain => domain.seller === "namecheap");

  return {
    target_ui: "search",
    registryPremiumDomain: premiumDomains.length > 0 ? "true" : "false",
    registryPremiumDomainValue: premiumDomains.reduce(
      (total, _domain) => total + (!isNaN(_domain.price) ? _domain.price : 0),
      0
    ),
    afternicPremiumDomain: afternicPremiumDomains.length > 0 ? "true" : "false",
    afternicDomainValue: afternicPremiumDomains.reduce(
      (total, _domain) => total + (!isNaN(_domain.price) ? _domain.price : 0),
      0
    ),
    sedoPremiumDomain: sedoPremiumDomains.length > 0 ? "true" : "false",
    sedoDomainValue: sedoPremiumDomains.reduce(
      (total, _domain) => total + (!isNaN(_domain.price) ? _domain.price : 0),
      0
    ),
    ncMarketplaceDomain: namecheapDomains.length > 0 ? "true" : "false",
    ncMarketplaceDomainValue: namecheapDomains.reduce(
      (total, _domain) => total + (!isNaN(_domain.price) ? _domain.price : 0),
      0
    ),
    cartValue: summary.Total,
    productValue: products.reduce(
      (value, product) => value + (!isNaN(product.price) ? product.price : 0),
      0
    ),
    domainValue: domains.reduce(
      (total, _domain) => total + (!isNaN(_domain.price) ? _domain.price : 0),
      0
    ),
    currency: summary.Currency || "USD",
    grossQs: qualities.grossQs,
    avgQs: qualities.avgQs,
    productsInCart: products.length > 0 ? "true" : "false",
    userLoggedIn: isAuthenticated() ? "true" : "false",
    usedBeastMode: beastMode ? "true" : "false",
    deviceType: getOSInfo(),
    browser: getBrowserType(),
    browserLocale: getBrowserLang(),
  };
};

/**
 * Log reward to banditML
 *
 *  @param {String} id      The product/domain added to the cart
 * @param {String} decisionID
 * @param {String} refId
 */
export const logReward = async (id, decisionID, _refId = null) => {
  let slug = id;
  // const bandit = await _getBandit();
  const { products: productsRTB } = await getRTBProducts();
  // await bandit.logReward({ addToCart: 1 }, banditExperiment, id, decisionID);

  if (slug.includes("easy-wp")) {
    slug = slug.replace(/(easy-wp)/, "ewp");
  }

  if (slug.includes("ox") || slug.includes("ssl")) {
    slug = slug.replace(/-(mo|yr)/, "");
  }

  if (slug.includes("ssl")) {
    slug = "positive-ssl";
  }

  const product = productsRTB.find(product => product.variant === slug);

  if (product) {
    // const { id, variant } = product;
    // await trackReward(refId, id, variant);
  }

  return true;
};

/**
 * Log reward to picks experiment
 *
 * @param {string} id
 * @param {string} decisionID
 */
export const logPickReward = async (_id, _decisionID) => {
  // const bandit = await _getBandit2();
  // await bandit.logReward({ addToCart: 1 }, picksExperiment, id, decisionID);
};

/**
 * Log decision based on shown Picks
 *
 * @param {Object} data
 */
export const logPicksDecision = async _decision => {
  // const bandit = await _getBandit2();
  // const data = {
  //   id: decision.id,
  //   decision,
  // };
  // await bandit.logDecision(
  //   bandit.getContext(picksExperiment),
  //   data,
  //   picksExperiment
  // );
};

/**
 * Get a list of tlds based on Bandit suggestion
 *
 * @return {Array<String>}
 */
export const getPicks = async () => {
  const _decision = await _getPicks();
  if (!_decision) return null;

  return _decision;
};

/**
 * Get a list of products based on current experiment.
 *
 * @param {Boolean} beastMode
 * @return {Promise<Array<Object>>}
 */
export const getProducts = async (_beastMode = false, addons = [], hasDomain) => {
  const decision = await _getDecision({}, addons, hasDomain);

  if (!decision) return null;

  const { id: decisionId, variantSlug, variantId, ids = [] } = decision;
  let options = [],
    products = [];
  products = ids;

  return {
    decisionId,
    variantSlug,
    variantId,
    options,
    products,
  };
};

/**
 * Get a list of domain suggestions based on the given domain names.
 *
 * @param  {Array<String>} domains
 * @return {Promise<Object>}
 */
export const getDomainSuggestion = async domains => {
  if (!Array.isArray(domains) && domains.length === 0) {
    return null;
  }
  const url = `${process.env.REACT_APP_AFTERMARKET}/domain/spin?domain=${domains.join(",")}`;
  const response = await axios.get(url);
  const data = response.data.data;
  if (!data) {
    return null;
  }
  return data
    .filter(domain => {
      return domain.price <= 8000 && domain.price > 0 && !domains.includes(domain.domain);
    })
    .shift();
};

/**
 * Get the gross quality scores from domains
 *
 * @param   {Array<Domain>} domains
 * @return {Promise<float>}
 */
export const getGrossQuality = async domains => {
  const ranks = await getRank();
  const tlds = domains.map(domain => domain.tld);
  const availableRanks = ranks.filter(rank => tlds.includes(rank.tld));
  let grossQs = 0,
    avgQs = 0;

  if (availableRanks.length > 0) {
    grossQs = availableRanks.reduce((value, rank) => value + rank.quality_score, 0);
    avgQs = grossQs / availableRanks.length;
  }

  return {
    grossQs,
    avgQs,
  };
};
