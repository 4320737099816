import axios from "utils/axios";

export const getTopAuctions = async () => {
  try {
    const url = process.env.REACT_APP_AFTERMARKET_AUCTIONS + "/client/api/domain-search-auctions";
    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAuctions = async (_query = "") => {
  try {
    const url = process.env.REACT_APP_AFTERMARKET_AUCTIONS + "/client/api/domain-search-auctions";
    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
