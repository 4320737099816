import React, { lazy, useEffect, useState, Fragment, Suspense } from "react";
import { observer, useLocalObservable } from "mobx-react-lite";

import SearchStore from "store/Search";
import { MODES, isValidMode } from "store/Search/utils";
import { getQueryStringParam, isEmbedded } from "utils/browser";
import { SearchContext } from "utils/context";
import { getRefId } from "utils/cart";

const Intro = lazy(() => import(/* webpackChunkName: "IntroComponent" */ "components/Intro"));
const Picks = lazy(() => import(/* webpackChunkName: "PicksComponent" */ "components/Picks"));
const Results = lazy(() => import(/* webpackChunkName: "ResultsComponent" */ "components/Results"));

import Search from "components/Search";

/**
 * Indicates whether the widget is embedded in a page other than the search
 * results. This makes it assume the beast mode by default.
 *
 * @type {Boolean}
 */
const isEmbed = isEmbedded();

/**
 * The query from the current URL.
 *
 * @type {String}
 */
const query = getQueryStringParam("domain");

/**
 * The search mode from the current URL.
 *
 * @type {String}
 */
const modeParam = getQueryStringParam("type");
const hns = getQueryStringParam("hns");
const mode =
  hns === "true"
    ? MODES.hns
    : modeParam === "beast" || isEmbed
    ? MODES.beast
    : isValidMode(modeParam)
    ? modeParam
    : MODES.standard;

/**
 * The share ID from the current URL.
 *
 * @type {String}
 */
const share = getQueryStringParam("share");

/**
 * The tldType from the current URL
 *
 * @type {String}
 */
const tldtype = getQueryStringParam("tldtype");

/**
 * The sort from the current URL
 *
 * @type {String}
 */
const sort = getQueryStringParam("sort");

/**
 * The search store.
 *
 * @type {SearchStore}
 */
const search = new SearchStore(query, mode, share, tldtype, sort);

/**
 * App container.
 *
 * This is the widget container where all main components reside.
 *
 * @return {React.FunctionComponentElement}
 * @author Gustavo Straube <gustavo@kettle.io>
 */
const App = () => {
  // Hooks
  const store = useLocalObservable(() => search);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      (async () => {
        await getRefId().catch(console.error);

        if (store.query && (store.isStandard() || store.isHNS())) {
          store.search().catch(console.error);
        }

        if (store.isAuctions()) {
          store.loadAuctions().catch(console.error);
        }

        setLoaded(true);
      })();
    }
  }, [loaded]);

  useEffect(() => {
    const handleConnectionIssues = () => {
      alert(
        "It appears we're having trouble connecting with the registry. Please try again shortly."
      );
    };

    window.addEventListener("search.conn.issue", handleConnectionIssues);

    return () => {
      window.removeEventListener("search.conn.issue", handleConnectionIssues);
    };
  });

  return (
    <SearchContext.Provider value={store}>
      <div className="wrapper">
        {isEmbed && (
          <Suspense fallback={<Fragment></Fragment>}>
            <Intro />
          </Suspense>
        )}
        {!isEmbed && !store.isBeast() && <Search defaultQuery={query} />}

        <Suspense fallback={<Fragment></Fragment>}>
          <Picks isEmbed={isEmbed} defaultQuery={query} />
        </Suspense>

        {!isEmbed && (
          <Suspense fallback={<Fragment></Fragment>}>
            <Results />
          </Suspense>
        )}
      </div>
    </SearchContext.Provider>
  );
};

export default observer(App);
