import axios from "utils/axios";
import { getProductSlug, getDescription } from "utils/product";
import { getUserFingerprint } from "utils/user";
import { getRefId } from "./cart";
import productsJSON from "data/products.json";
import rtbProducts from "data/rtb-extra-products.json";

/**
 * RTB integration.
 *
 * @author Gustavo Straube <gustavo@kettle.io>
 */

/**
 * Current env.
 *
 * @type {String}
 */
const env = process.env.REACT_APP_ENV === "production" ? "production" : "sandbox";

/**
 * The token to use with requests to Clearbit API.
 *
 * @type {String|null}
 */
let _clearbitToken = null;

/**
 * The promise used to fetch the rank.
 *
 * @type {Promise}
 */
const _rank = (async () => {
  const url = process.env.REACT_APP_RTB + "/rank";
  const response = await axios.get(url);
  return response.data.ranks.map(position => {
    position.tld = position.tld.toLowerCase();
    return position;
  });
})();

/**
 * The promise used to fetch details about all TLDs.
 *
 * @type {Promise}
 */
const _domains = (async () => {
  const url = process.env.REACT_APP_RTB + "/domains";
  const response = await axios.get(url);
  return response.data.domains.map(tld => {
    tld.name = tld.name.toLowerCase();
    return tld;
  });
})();

/**
 * The promise used to fetch the categories.
 *
 * @type {Promise}
 */
const _filters = (async () => {
  const domains = await _domains;
  const filters = {
    Popular: [],
  };
  domains.forEach(tld => {
    tld.category.forEach(category => {
      if (!filters[category]) {
        filters[category] = [];
      }
      filters[category].push(tld.name);
    });
  });

  // Popular
  const rank = await getRank();
  const picks = await getTldPicks();
  const popular = rank.map(position => position.tld);
  picks.forEach(tld => {
    if (!popular.includes(tld)) {
      popular.push("#" + tld);
    }
  });
  filters.Popular = popular;

  // Miscellaneous
  const tlds = await getTlds();
  const misc = tlds.filter(tld => {
    let found = false;
    for (let filter in filters) {
      if (filters[filter].indexOf(tld.Name) !== -1) {
        found = true;
        break;
      }
    }
    return !found;
  });
  if (misc.length > 0) {
    filters.Miscellaneous = misc.map(tld => tld.Name);
  }

  return filters;
})();

/**
 * The promise used to fetch details for all TLDs (e.g. tooltip and promo text).
 *
 * @type {Promise}
 */
export const _details = (async () => {
  const domains = await _domains;
  const details = {};
  domains.forEach(tld => {
    details[tld.name] = tld;
  });
  return details;
})();

/**
 * The promise used to fetch the TLDs.
 *
 * @type {Promise}
 */
const _tlds = (async () => {
  const url = process.env.REACT_APP_TLD;
  const response = await axios.get(url);
  const tlds = response.data;
  const details = await _details;

  return tlds.map(tld => {
    tld.Name = tld.Name.toLowerCase();

    if (!details[tld.Name]) {
      return tld;
    }

    tld.Pricing.Hint = details[tld.Name].promo_text;
    tld.Pricing.Enable_eap = details[tld.Name].enable_eap;
    tld.Pricing.Tooltip = details[tld.Name].tooltip;
    return tld;
  });
})();

/**
 * The promise used to fetch the TLD picks.
 *
 * @type {Promise}
 */
const _tldPicks = (async () => {
  const url = process.env.REACT_APP_RTB + "/all_picks";
  const response = await axios.get(url);
  return response.data.picks.map(pick => pick.tld.toLowerCase());
})();

/**
 * Get the list of TLDs that uses premiums api endpoint
 *
 * @tyoe {Promise}
 */
const _premiumTlds = (async () => {
  const url = process.env.REACT_APP_PREMIUM_TLDS_CSV;
  const response = await axios.get(url);
  const data = response.data.split(/\r\n|\n/);
  return data || [];
})();

/**
 * Get the list of HNS TLDs
 *
 * @tyoe {Promise}
 */
const _hnsTlds = (async () => {
  const url = process.env.REACT_APP_HNS_TLDS_JSON;
  const response = await axios.get(url);
  const data = response.data;
  const tlds = (data || []).map(tld => tld.replace(/^(\.)/, ""));
  return [...new Set(tlds)];
})();

/**
 * The promise used to fetch the products.
 *
 * @type {Promise}
 */
const _products = (async () => {
  // const url = process.env.REACT_APP_RTB + '/products';
  // const response = await axios.get(url);
  const products = productsJSON.products.map(product => {
    const slug = getProductSlug(product.id);
    product.slug = slug;
    product.tooltip = slug === "easy-wp" ? getDescription("easy-wp-starter") : getDescription(slug);

    if (slug === "business-card-maker") {
      product.interval = "yr";
    }
    if (slug === "easy-wp") {
      product.name = "WordPress Hosting";
    }

    if (slug === "ssl") {
      product.name = "SSL";
    }

    if (slug === "stellar") {
      product.name = "Web Hosting";
    }

    return product;
  });
  return products;
})();

const _productsRTB = (async () => {
  // const url = 'https://d1dijnkjnmzy2z.cloudfront.net/product-rank.json';
  const url = process.env.REACT_APP_RTB + "/products/rank";
  const response = await axios.get(url);
  return {
    products: [...response.data.products, ...rtbProducts],
  };
})();

/**
 * Get the token to use with requests to Clearbit API.
 *
 * @returns {String}
 */
const _getClearbitToken = async () => {
  if (_clearbitToken === null) {
    const url = `${process.env.REACT_APP_CLEARBIT}/token`;
    const {
      data: { token },
    } = await axios.post(url);
    _clearbitToken = token;
  }

  return _clearbitToken;
};

/**
 * The user fingerprint.
 *
 * @type {String}
 */
export const fingerprint = getUserFingerprint();

/**
 * Get the ranks from RTB.
 *
 * @return {Promise<Array>}
 */
export const getRank = () => _rank;

/**
 * Get the filters from RTB.
 *
 * @return {Promise<Object.<String, Array<String>>>}
 */
export const getFilters = () => _filters;

/**
 * Get the TLDs.
 *
 * @return {Promise<Array<Object>>}
 */
export const getTlds = () => _tlds;

/**
 * Find a single TLD.
 *
 * @param  {String} name
 * @return {Promise<Object|null>}
 */
export const getTld = async name => {
  const tlds = await getTlds();
  return tlds.find(tld => {
    return tld.Name === name;
  });
};

/**
 * Get the TLD picks.
 *
 * @return {Promise<Array<String>>}
 */
export const getTldPicks = () => _tldPicks;

/**
 * Get the list of TLDs that uses premium endpoint
 *
 * @return {Promise<Array<String>>}
 */
export const getPremiumTlds = () => _premiumTlds;

/**
 * Get the list of HNS TLDs
 *
 * @return {Promise<Array<String>>}
 */
export const getHnsTlds = () => _hnsTlds;

/**
 * Get the suggested results (picks) based on the given query.
 *
 * @return {Promise<Array<Object>>}
 */
export const getPicks = async query => {
  const url = `${process.env.REACT_APP_RTB}/picks/${query}?session_id=${fingerprint}`;
  const response = await axios.get(url);
  const picks = response.data.picks;
  picks.sort((a, b) => {
    return a.priority - b.priority;
  });
  return picks;
};

/**
 * Get the products.
 *
 * @return {Promise<Array<Object>>}
 */
export const getProducts = () => _products;

/**
 * Get the product price from RTB based on slug
 *
 * @param {String} slug
 * @param {String} plan
 * @param {String} interval
 * @param {boolean} domain = false
 * @return {Number}
 */
export const getProductPrice = async (
  slug,
  plan,
  interval,
  domain = false,
  getFullProduct = false
) => {
  if (slug === "easy-wp") {
    slug = "ewp";
  }

  if (slug.includes("ox")) {
    interval = null;
  }

  let id = [slug, plan, interval].filter(part => !!part).join("-");
  if (slug === "ssl") {
    id = "positive-ssl";
  }

  if (slug === "premium-dns") {
    id = "premium-dns";
  }

  if (slug.includes("social") || slug.includes("review")) {
    id = plan;
  }

  const data = await getRTBProducts();
  const product = data.products.find(product => product.variant === id);

  if (product) {
    if (getFullProduct) {
      return product;
    }

    return !domain && product.domain_less_promo_price
      ? product.domain_less_promo_price
      : product.price;
  }

  return null;
};

/**
 * Get the products from RTB Endpoint
 *
 * @return {Promise<Array<Object>>}
 */
export const getRTBProducts = () => _productsRTB;

/**
 * Get the suggested featured domain based on the given query.
 *
 * @return {Promise<Object>}
 */
export const getFeatured = async query => {
  const ref_id = await getRefId();
  const ref_id_param = `&refid=${ref_id}`;
  const url = `${process.env.REACT_APP_RTB}/search/${query}?session_id=${fingerprint}&search=false${
    ref_id ? ref_id_param : ""
  }`;
  const response = await axios.get(url);
  return response.data.exact_match;
};

/**
 * Get the suggested domain based on the given query.
 *
 * @return {Promise<Object>}
 */
export const getFeaturedSuggestions = async query => {
  try {
    const url = `${process.env.REACT_APP_AFTERMARKET}/suggestions/${encodeURIComponent(query)}`;
    const response = await axios.get(url);

    if (!Array.isArray(response.data) || response.data.length === 0) {
      return [];
    }

    const options = response.data.sort((a, b) => b._score - a._score);
    const list = options.map(item => item["domain-name"]);
    const amResponse = await axios.get(
      process.env.REACT_APP_AFTERMARKET +
        "/domain/status?domain=" +
        list.filter(domain => !domain.endsWith(".fr")).join(",")
    );

    const aftermarketDomains = (amResponse.data.data || []).map(item => {
      const score = options.find(opt => opt["domain-name"] === item.domain);
      return {
        ...item,
        score: score._score,
      };
    });

    return aftermarketDomains
      .filter(item => item.status && item.status !== "notfound")
      .filter(item => item.price <= 3000)
      .filter(item => item.type === "buynow")
      .sort((a, b) => b.score - a.score)
      .map(data => {
        const domain = data.domain.toLowerCase();
        return {
          domain: domain,
          tld: domain.split(".").slice(1).join("."),
          price: data.price,
          score: data.score,
          fxed: +data.score.toFixed(2),
          aftermarket: data,
        };
      });
  } catch (e) {
    console.error(e);
    return [];
  }
};

/**
 * Get whois information for the given domain.
 *
 * @param  {String} domain
 * @return {Promise<Object>}
 */
export const getWhois = async domain => {
  const url = `${process.env.REACT_APP_STATUS_HTTP}/whois?domains=${domain}`;
  try {
    const response = await axios.get(url, { timeout: 20000 });
    return response.data.results.find(result => {
      return result.domain === domain;
    });
  } catch (error) {
    if (error.code === "ECONNABORTED") {
      window.dispatchEvent(new CustomEvent("search.conn.issue"));
    }
    return undefined;
  }
};

/**
 * Get certain domain status
 *
 * @param {String} domain
 * @param {Boolean} useAftermarket = true
 * @return {Promise<Object>}
 */
export const getDomainStatus = async (domain, useAftermarket = true) => {
  let aftermarket = null;
  const promises = [
    axios.get(`${process.env.REACT_APP_STATUS_HTTP}/domainStatus?domains=${domain}`, {
      timeout: 20000,
    }),
  ];

  if (useAftermarket && !domain.endsWith(".fr")) {
    promises.push(axios.get(`${process.env.REACT_APP_AFTERMARKET}/domain/status?domain=${domain}`));
  }

  let statusResponse = {
      data: {
        status: [],
      },
    },
    aftermarketResponse = {
      data: {
        data: [],
      },
    };

  try {
    const [_statusResponse, _aftermarketResponse] = await Promise.all(promises);
    statusResponse = _statusResponse;
    aftermarketResponse = _aftermarketResponse;
  } catch (error) {
    if (error.code === "ECONNABORTED") {
      window.dispatchEvent(new CustomEvent("search.conn.issue"));
    }
  }

  const status = statusResponse.data.status.find(result => {
    return result.name === domain;
  });

  if (aftermarketResponse) {
    aftermarket = aftermarketResponse.data.data.find(result => {
      return result.domain === domain;
    });
  }

  return {
    status,
    aftermarket,
  };
};

/**
 * Get company information for the given domain.
 *
 * @param  {String} domain
 * @return {Promise<Object>}
 */
export const getCompany = async domain => {
  const token = await _getClearbitToken();
  const validationUrl = process.env.REACT_APP_CLEARBIT_VALIDATION;
  const url = `${process.env.REACT_APP_CLEARBIT}/?domain=${domain}`;
  try {
    const { data: validation } = await axios.get(validationUrl, {
      headers: {
        "Content-Type": "application/json",
      },
      data: {},
    });

    if (!validation.isValid) return null;

    const response = await axios.get(url, {
      validateStatus() {
        return true;
      },
      headers: {
        "X-CSRF-TOKEN": token,
      },
    });
    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * Track a cart action.
 *
 * @param  {String} [domain=null]
 * @param  {String} [product=null]
 * @param  {Boolean} [card=false]
 * @param  {Boolean} [beast=false]
 * @param  {String} [refId=false]
 * @param  {String} [spin_type=null]
 * @param  {Boolean} [hasSuggestion=false]
 * @param  {Boolean} [conversion_type=null]
 * @return {Promise<Object>}
 */
export const trackCart = async (
  domain = null,
  product = null,
  card = false,
  beast = false,
  refId = null,
  spin_type = null,
  hasSuggestion = false,
  conversion_type = null
) => {
  const url = `${process.env.REACT_APP_RTB}/cart`;

  const data = {
    session_id: parseInt(fingerprint),
    ...(refId ? { ref_id: refId } : {}),
  };
  if (domain) {
    data.domain = domain;
  }
  if (product) {
    data.product_name = product;
    data.product = true;
  }
  if (card && product) {
    data.cards = true;
  }
  if (beast) {
    data.beast = true;
  }

  if (spin_type) {
    data.product_name = `aftermarket_spin_${spin_type}`;
    data.product = false;
  }

  if (hasSuggestion) {
    data.spin_shown = hasSuggestion;
  }

  if (conversion_type) {
    data.conversion_type = conversion_type;
  }

  if (card && domain && !product) {
    data.conversion_type = "picks";
  }

  const response = await axios.post(url, data);

  return response.data;
};

/**
 * Track an event using `ga.js`, from Google Analytics.
 *
 * @param  {String} label
 * @param  {String} [value=null]
 * @return {void}
 */
export const trackEvent = (label, value = null) => {
  if (!window._gaq) {
    console.warn && console.warn("GA queue (_gaq) is undefined");
    return;
  }

  const event = ["_trackEvent", "Domain Search", label];

  if (value) {
    event.push(value);
  }

  window._gaq.push(event);
};

/**
 * Track a domain that failed to be added to cart after DRP check.
 *
 * @param  {String} domain
 * @param  {String} [type='cart']
 * @return {Promise<Boolean>}
 */
export const trackFail = async (domain, type = "cart") => {
  if (env !== "production") {
    return true;
  }
  const url = "https://us-central1-domain-search-143520.cloudfunctions.net/drp-fail-logging";
  const response = await axios.post(url, {
    domain,
    type,
  });
  return response.data.status;
};

/**
 * Alias for trackFail function.
 *
 * @param  {String} domain
 * @param  {String} [type='cart']
 * @return {Promise<Boolean>}
 */
export const trackAction = trackFail;

/**
 * Track a hit into Clearbit API.
 *
 * @param  {String} domain
 * @param  {Boolean} success
 * @return {Promise<Boolean>}
 */
export const trackHit = async (domain, success) => {
  if (env !== "production") {
    return true;
  }
  const url = "https://us-central1-domain-search-143520.cloudfunctions.net/drp-fail-logging";
  const response = await axios.post(url, {
    type: "hit",
    domain,
    success,
  });
  return response.data.status;
};

/**
 * Track the option selected based on the current suggestion
 *
 * @param {String} query
 * @param {String} suggestion
 * @param {Number} feedback
 */
export const trackSuggestionFeedback = async (query = "", suggestion = "", feedback = 1) => {
  try {
    const url = `${process.env.REACT_APP_FEATURED_SUGGESTION}/rtb-add-feedback`;
    const data = new FormData();
    data.append("user_input", query);
    data.append("suggestion_shown", suggestion);
    data.append("feedback", feedback);

    await axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.error("Error tracking the feedback");
  }
};

/**
 * Get domain hacks for the given word.
 *
 * @param  {String} word
 * @return {Promise<Array<String>>}
 */
export const getHacks = async word => {
  const url = `${process.env.REACT_APP_RTB}/search/hacks/${word}?session_id=${fingerprint}`;
  const response = await axios.get(url);
  return response.data.domains.map(domain => {
    return domain.name.toLowerCase();
  });
};

/**
 * Get the plural of given word.
 *
 * @param  {String} word
 * @return {Promise<Array<String>>}
 */
export const getPlural = async word => {
  const url = `${process.env.REACT_APP_RTB}/search/plural/${word}?session_id=${fingerprint}`;
  const response = await axios.get(url);
  return response.data.plural;
};

/**
 * Check against clearbit if the IP is valid
 *
 * @return {boolean}
 */
export const isValidIp = async () => {
  const validationUrl = process.env.REACT_APP_CLEARBIT_VALIDATION + "?ips=true";
  const { data: validation } = await axios.get(validationUrl, {
    headers: {
      "Content-Type": "application/json",
    },
    data: {},
  });
  return validation.isValid || false;
};

/**
 * Log Rewards to Bandit NC Endpoint
 *
 * @param {string} refId
 * @param {int} productId
 * @param {string} variantId
 * @param {string} rewardType
 */
export const trackReward = async (refId, productId, variantId, rewardType = "cart") => {
  const host = "https://bandit.namecheapapi.com";
  const url = `${host}/api/v1/reward`;

  try {
    await axios.post(
      url,
      {
        ref_id: refId,
        product_id: productId,
        product_variant: variantId,
        reward_type: rewardType,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return true;
  } catch (error) {
    console.error("Reward error", error);
    return false;
  }
};
