import DownloadWorker from "utils/download.worker";
import QueryWorker from "utils/query.worker";

// Download

/**
 * The DownloadWorker instance.
 *
 * @type {DownloadWorker}
 */
const downloadWorker = new DownloadWorker();

/**
 * Create a file export for the given domains.
 *
 * @param  {Array<String>} domains
 * @param  {Object} params
 * @param  {String} code
 * @param  {Function<Number>} callback
 * @return {Promise<String>}
 */
export const download = (domains, params, code, callback) => {
  let count = 0;
  return new Promise((resolve, reject) => {
    downloadWorker.onmessage = function (event) {
      callback(++count);
      const data = event.data;
      if (data) {
        return resolve(data);
      }
    };

    downloadWorker.onerror = function (error) {
      return reject(error);
    };

    downloadWorker.postMessage([domains, params, code]);
  });
};

// Query

/**
 * The QueryWorker instance.
 *
 * @type {QueryWorker}
 */
const queryWorker = new QueryWorker();

/**
 * The worker queues.
 *
 * @type {Object.<String, Array>}
 */
const queues = {
  status: [],
  aftermarket: [],
  premium: [],
};

/**
 * Handle the messages received from the worker.
 *
 * @param  {Object} event
 * @return {void}
 */
queryWorker.onmessage = function (event) {
  const [type, data] = event.data;
  queues[type].push(data);
};

/**
 * Handle the errors received from the worker.
 *
 * @param  {Error|String} error
 * @return {void}
 */
queryWorker.onerror = function (error) {
  console.error(error);
};

/**
 * WebWorker based version of the query class.
 *
 * @type {Object.<String, Function>}
 */
export const query = {
  /**
   * Run the status query for the given domains.
   *
   * The callback passed is executed for each domain.
   *
   * @param  {Array<String>} domains
   * @param  {Function} callback
   * @return {Promise}
   */
  status(domains, callback) {
    return new Promise((resolve, reject) => {
      try {
        let count = 0;
        const loop = setInterval(() => {
          while (queues.status.length > 0) {
            const domain = queues.status.shift();
            callback(domain);
            count++;
          }
          if (count >= domains.length) {
            clearTimeout(loop);
            return resolve();
          }
        }, 250);
        queryWorker.postMessage(["status", domains]);
      } catch (error) {
        console.error(error);
        return reject(error);
      }
    });
  },

  /**
   * Run the aftermarket query for the given domains.
   *
   * The callback passed is executed for each domain.
   *
   * @param  {Array<String>} domains
   * @param  {Function} callback
   * @return {Promise}
   */
  aftermarket(domains, callback) {
    return new Promise((resolve, reject) => {
      try {
        let count = 0;
        const loop = setInterval(() => {
          while (queues.aftermarket.length > 0) {
            const domain = queues.aftermarket.shift();
            callback(domain);
            count++;
          }
          if (count >= domains.length) {
            clearTimeout(loop);
            return resolve();
          }
        }, 250);
        queryWorker.postMessage(["aftermarket", domains]);
      } catch (error) {
        console.error(error);
        return reject(error);
      }
    });
  },

  /**
   * Run the premium query for the given domain.
   *
   * The callback passed is executed for the domain.
   *
   * @param  {String} domain
   * @param  {Function} callback
   * @return {Promise}
   */
  premium(domain, callback) {
    return new Promise((resolve, reject) => {
      try {
        const loop = setInterval(() => {
          const index = queues.premium.findIndex(status => status.domain === domain);
          if (index !== -1) {
            callback(queues.premium.splice(index, 1)[0]);
            clearTimeout(loop);
            return resolve();
          }
        }, 250);
        queryWorker.postMessage(["premium", domain]);
      } catch (error) {
        console.error(error);
        return reject(error);
      }
    });
  },

  /**
   * Connect or reconnect the websocket layer.
   *
   * @param  {Boolean} [batch=false]
   * @return {void}
   */
  conn(batch = false) {
    queryWorker.postMessage(["conn", batch]);
  },

  /**
   * Close the query connection without reconnection
   *
   * @return {void}
   */
  close() {
    queryWorker.postMessage(["close"]);
  },
};
