import React from "react";

const StandardModeWireframe = () => {
  return (
    <section className="beast-wireframe">
      <header>
        <span className="bw-title"></span>
        <span className="bw-options"></span>
        <span className="bw-toggler"></span>
      </header>
    </section>
  );
};

export default StandardModeWireframe;
