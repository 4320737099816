import axios from "axios";
import crc32 from "crc-32";

/**
 * Custom Axios client for RTB calls
 */
const axiosClient = axios.create();

/**
 * Check if Url is Namecheap API
 * @param {string} url
 * @return {boolean}
 */
const _checkIfNcApi = url => {
  if (!url) return false;

  return url.match(
    /(sb[.-])?(rtb|aftermarket|premiums|pricerequest|business-lookup|domain-suggestion)?.namecheapapi.com/
  );
};

/**
 * Check if Url is Revved
 * @param {string} url
 * @return {boolean}
 */
const _checkIfRevved = url => {
  if (!url) return false;

  return url.match(/https:\/\/((sb-)?domains)?.revved.com/);
};

/**
 * Calculate signature
 * copied from domain search
 *
 * @param {string} method
 * @param {URL} url
 * @returns
 */
const _calculateRequestSignature = (method, url, type) => {
  const key =
    type === "revved" ? "8f6c7d5691eebd3b5090dc6b06755d58" : "815e7ef93be85bebe5959f6f72d7e542";
  let nonce = [...Array(32)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");
  let toSign = key + " " + nonce + " " + method.toUpperCase() + " " + url.pathname + " ";
  let paramsToSign = [];

  if (url.search && url.search.length > 0 && url.search[0] == "?") {
    let params = url.search.substring(1).split("&");
    for (let x = 0; x < params.length; x++) {
      let kv = params[x].split("=");
      if (kv.length == 2) {
        if (kv[0] != "rcs") paramsToSign.push([kv[0], kv[1]]);
      }
    }
  }
  paramsToSign.sort((a, b) => {
    if (a[0] < b[0]) return -1;
    else if (a[0] > b[0]) return 1;
    else return 0;
  });
  toSign += paramsToSign.map(p => p[0] + "=" + encodeURIComponent(p[1])).join("&");

  let checksumStr = JSON.stringify({ val: crc32.str(toSign), n: nonce });
  let encodedStr = "";
  for (let x = 0; x < checksumStr.length; x++) {
    encodedStr += String.fromCharCode(checksumStr.charCodeAt(x) ^ 0x49);
  }

  return btoa(encodedStr);
};

/**
 * Generate a checksum for RTB API calls
 */
axiosClient.interceptors.request.use(config => {
  const url = new URL(config.url);
  const isRevved = _checkIfRevved(config.url);
  const isNamecheapApi = _checkIfNcApi(config.url);
  let type = "any";

  if (isNamecheapApi) {
    type = "nc";
  }

  if (isRevved) {
    type = "revved";
  }

  // only do this if the domain matches the Namecheap API & revved URL
  if (!isRevved && !isNamecheapApi) {
    return config;
  }

  const requestSig = _calculateRequestSignature(config.method, new URL(url.toString()), type);
  const requestSigParam = "rcs=" + encodeURIComponent(requestSig);
  if (!url.search || url.search.length == 0) {
    url.search = "?" + requestSigParam;
  } else {
    url.search += "&" + requestSigParam;
  }

  config.url = url.toString();

  return config;
});

export default axiosClient;
