import { action, makeObservable, observable } from "mobx";

/**
 * The domain statuses.
 *
 * @type {Object.<String, String>}
 */
export const STATUSES = {
  unavailable: "unavailable",
  available: "available",
  premium: "premium",
  offer: "offer",
  platinum: "platinum",
};

/**
 * The domain states.
 *
 * @type {Object.<String, String>}
 */
export const STATES = {
  fetching: "fetching",
  ready: "ready",
  purchasing: "purchasing", // Adding to cart
  purchased: "purchased",
};

/**
 * The domain model.
 *
 * @author Gustavo Straube <gustavo@kettle.io>
 */
class Domain {
  /**
   * The domain status.
   *
   * @type {String}
   * @private
   */
  _status = STATUSES.unavailable;

  /**
   * The domain state.
   *
   * @type {String}
   * @private
   */
  _state = STATES.fetching;

  /**
   * Check if status is already loaded from WS
   *
   * @type {Boolean}
   * @private
   */
  _statusLoaded = false;

  /**
   * The domain price.
   *
   * @type {Object}
   * @private
   */
  _price = {
    price: 0,
    regular: 0,
    renewal: 0,
    isNormal: true,
  };

  /**
   * The domain SLD.
   *
   * @type {String}
   */
  sld = "";

  /**
   * The domain TLD.
   *
   * @type {String}
   */
  tld = "";

  /**
   * The domain hint (label).
   *
   * @type {String}
   */
  hint = "";

  /**
   * The domain tooltip.
   *
   * @type {String}
   */
  tooltip = "";

  /**
   * is eap domain.
   *
   * @type {Boolean}
   */
  enable_eap = false;

  /**
   * The domain sequence.
   *
   * @type {Number}
   */
  sequence = 9999;

  /**
   * The registry that owns the domain.
   *
   * @type {String}
   */
  registry = null;

  /**
   * The Namecheap ID for the domain.
   *
   * @type {String}
   */
  namecheapId = null;

  /**
   * The error associated to this domain.
   *
   * This is attribute is usually associated with the cart API.
   *
   * @type {String}
   */
  error = null;

  /**
   * The API data related to this domain.
   *
   * @type {Object}
   */
  data = {};

  /**
   * Indicates whether this domain is hidden.
   *
   * @type {Boolean}
   */
  hidden = false;

  /**
   * The products purchased for this domain.
   *
   * @type {Array}
   */
  products = [];

  /**
   * The domain suggestion for this domain.
   *
   * @type {Domain}
   */
  suggestion = null;

  /**
   * Mark if suggestions were loaded from Bandit
   *
   * @type {Boolean}
   */
  suggestionsLoaded = false;

  /**
   * The products suggestions for this domain
   * These suggestions were generated by bandit
   *
   * @type {Array} array of products
   */
  productSuggestions = [];

  /**
   * BanditML Variant/Decision Id
   *
   * @type {String} id of BanditID decision
   */
  decisionID = null;

  /**
   * BanditML Decision Id
   *
   * @type {String} id of the Bandit Decision
   */
  picksDecision = null;

  /**
   * BanditML Decision Score
   *
   * @type {Number} Score
   */
  picksScore = null;

  /**
   * BanidML Variant slug
   *
   * @type {String} slug of Bandit Variant
   */
  variantSlug = null;

  /**
   * BanditML Variant ID
   *
   * @type {String} id of the displayed variant
   */
  variantId = null;

  /**
   * If the domain is aftermarket, contains spin type
   *
   * @type {String}
   */
  spinType = null;

  /**
   * If the item is in the cart, we will store unique identifier
   *
   * @param {String} cartKey
   */
  cartKey = null;

  /**
   * If the domain is a suggestion or if it is returned in the search
   *
   * @param {Boolean} isSuggestion
   */
  isSuggestion = null;

  /**
   *
   * @param {Boolean} isFeaturedSuggestion
   */
  isFeaturedSuggestion = false;

  /**
   * Create a new instance.
   *
   * @param {Object} [data={}]
   */
  constructor(data = {}) {
    makeObservable(this, {
      _status: observable,
      _state: observable,
      _price: observable,
      hint: observable,
      tooltip: observable,
      enable_eap: observable,
      sequence: observable,
      registry: observable,
      error: observable,
      hidden: observable,
      products: observable,
      suggestion: observable,
      isSuggestion: observable,
      isFeaturedSuggestion: observable,
      productSuggestions: observable,
      suggestionsLoaded: observable,
      decisionID: observable,
      variantSlug: observable,
      variantId: observable,
      cartKey: observable,
      picksDecision: observable,
      picksScore: observable,
      constructor: action,
      setUnavailable: action,
      setAvailable: action,
      setPremium: action,
      setOffer: action,
      setPlatinum: action,
    });
    if (data.price) {
      data._price = { ...this._price, ...data.price };
      delete data.price;
    }
    Object.assign(this, data);
  }

  /**
   * Get the domain ID.
   *
   * The ID is made by joining the SLD and TLD with a `.` (dot).
   *
   * @return {String}
   */
  get id() {
    return this.sld + "." + this.tld;
  }

  /**
   * Get the domain price.
   *
   * @return {Object.<String, Number>}
   */
  get price() {
    return this._price;
  }

  /**
   * Check whether the current domain status is unavailable.
   *
   * @return {Boolean}
   */
  isUnavailable() {
    return this._status === STATUSES.unavailable;
  }

  /**
   * Set the current domain status to unavailable.
   *
   * The state is also updated to ready if it's fetching.
   *
   * @return {void}
   */
  setUnavailable() {
    this._status = STATUSES.unavailable;
    if (this.isFetching()) {
      this.setReady();
    }
  }

  /**
   * Check whether the current domain status is available.
   *
   * @return {Boolean}
   */
  isAvailable() {
    return this._status === STATUSES.available;
  }

  /**
   * Set the current domain status to available.
   *
   * The state is also updated to ready if it's fetching.
   *
   * @return {void}
   */
  setAvailable() {
    this._status = STATUSES.available;
    if (this.isFetching()) {
      this.setReady();
    }
  }

  /**
   * Check whether the current domain status is premium.
   *
   * @return {Boolean}
   */
  isPremium() {
    return this._status === STATUSES.premium;
  }

  /**
   * Set the current domain status to premium.
   *
   * The state is also updated to ready if it's fetching and a registry is
   * passed.
   *
   * @param  {String} [registry=null]
   * @param  {Object} [price=null]
   * @return {void}
   */
  setPremium(registry = null, price = null) {
    this._status = STATUSES.premium;

    if (!registry) {
      return;
    }

    this.registry = registry;

    if (price) {
      this.setPrice(price);
    }

    if (this.isFetching()) {
      this.setReady();
    }
  }

  /**
   * Set the current domain status to unavailable.
   *
   * The state is also updated to ready if it's fetching and a registry is
   * passed.
   *
   * @param  {String} [registry=null]
   * @param  {Object} [price=null]
   * @return {void}
   */
  setOffer(registry = null, price = null) {
    this._status = STATUSES.unavailable;

    if (!registry) {
      return;
    }

    this.registry = registry;

    if (price) {
      this.setPrice(price);
    }

    if (this.isFetching()) {
      this.setReady();
    }
  }

  /**
   * Check whether the current domain status is platinum.
   *
   * @return {Boolean}
   */
  isPlatinum() {
    return this._status === STATUSES.platinum;
  }

  /**
   * Set the current domain status to platinum.
   *
   * The state is also updated to ready if it's fetching.
   *
   * @param  {String} [registry]
   * @return {void}
   */
  setPlatinum(registry) {
    this._status = STATUSES.platinum;
    this.registry = registry;
    this.setPrice({
      price: 0,
    });

    if (this.isFetching()) {
      this.setReady();
    }
  }

  /**
   * Check whether the current domain state is fetching.
   *
   * @return {Boolean}
   */
  isFetching() {
    return this._state === STATES.fetching;
  }

  /**
   * Set the current domain state to fetching.
   *
   * @return {void}
   */
  setFetching() {
    this._state = STATES.fetching;
  }

  /**
   * Check whether the current domain state is ready.
   *
   * @return {Boolean}
   */
  isReady() {
    return this._state === STATES.ready;
  }

  /**
   * Set the current domain state to ready.
   *
   * @return {void}
   */
  setReady() {
    this._state = STATES.ready;
  }

  /**
   * Set if the domain status is loaded
   *
   * @return {void}
   */
  setStatusLoaded() {
    this._statusLoaded = true;
  }

  /**
   * Check whether the current domain is has a status loaded
   *
   * @return {Boolean}
   */
  isStatusLoaded() {
    return this._statusLoaded;
  }

  /**
   * Check whether the current domain state is purchasing.
   *
   * @return {Boolean}
   */
  isPurchasing() {
    return this._state === STATES.purchasing;
  }

  /**
   * Set the current domain state to purchasing.
   *
   * @return {void}
   */
  setPurchasing() {
    this._state = STATES.purchasing;
  }

  /**
   * Check whether the current domain state is purchased.
   *
   * @return {Boolean}
   */
  isPurchased() {
    return this._state === STATES.purchased;
  }

  /**
   * Set the current domain state to purchased.
   *
   * @return {void}
   */
  setPurchased() {
    this._state = STATES.purchased;
  }

  /**
   * Set the price, overriding current value.
   *
   * @param  {Object} price
   * @return {void}
   */
  setPrice(price) {
    if (price.price && price.price !== this._price.price) {
      price.isNormal = false;
    }
    this._price = { ...this._price, ...price };
  }

  /**
   * Check whether the current domain is financed.
   *
   * @return {Boolean}
   */
  isFinanced() {
    return this.registry === "financed";
  }

  /**
   * Check whether the current domain is from Sedo.
   *
   * Only domains that can't be added directly to cart (buynow + fast
   * transfer) will be considered Sedo's. Also, restricted TLDs are alwasy
   * considered Sedo's.
   *
   * @return {Boolean}
   */
  isSedo() {
    return this.registry === "sedo" && (!this.isBuyNow() || this.isRestrictedTld());
  }

  /**
   * Check whether the current domain is from `buynow` type and also a fast
   * transfer.
   *
   * @return {Boolean}
   */
  isBuyNowFastTransfer() {
    return (
      this.data.aftermarket &&
      this.data.aftermarket.type === "buynow" &&
      this.data.aftermarket.fast_transfer
    );
  }

  /**
   * Check whether the current domain is from `buynow` type.
   *
   * @return {Boolean}
   */
  isBuyNow() {
    return this.data.aftermarket && this.data.aftermarket.type === "buynow";
  }

  /**
   * Check whether the current domain has a restricted TLD.
   *
   * @return {Boolean}
   */
  isRestrictedTld() {
    return [
      "ai",
      "gdn",
      "is",
      "observer",
      "to",
      "cm",
      "nu",
      "sg",
      "fr",
      "xn–6frz82g",
      "yokohama",
      "realty",
      "osaka",
      "mom",
      "kyoto",
      "eu",
    ].includes(this.tld);
  }

  /**
   * Check whether the current domain is banned from registration.
   *
   * @return {Boolean}
   */
  isBanned() {
    return this.error && this.error.toLowerCase() === "banned";
  }

  /**
   * Check whether the current domain is a registry premium.
   *
   * @return {Boolean}
   */
  isRegistryPremium() {
    return (
      this.isPremium() && this.data.status && this.data.status.premium && this.data.status.available
    );
  }

  /**
   * Check whether the price of this domain is the same every year.
   *
   * @return {Boolean}
   */
  isYearly() {
    if (this.shouldShowRenewal()) {
      return false;
    }

    if (this.isPremium() && ["eco", "club", "global", "cam"].includes(this.tld)) {
      return false;
    }

    return !["afternic", "namecheap", "sedo"].includes(this.registry);
  }

  /**
   * Check whether this domain can be added to cart.
   *
   * @return {Boolean}
   */
  canAddToCart() {
    return (
      !this.error &&
      this.isReady() &&
      (this.isAvailable() || this.isPremium()) &&
      !this.isSedo() &&
      !this.isFinanced()
    );
  }

  /**
   * Check whether this domain can should be shown based on the given params.
   *
   * @param  {Object} params
   * @param  {Currency} currency
   * @return {Boolean}
   */
  shouldShow(params, currency) {
    if (!params) {
      return true;
    }

    // hide domains if hide available is active and domain is unavailable or is platinum
    // if (params.options && params.options.available && this.isUnavailable()) {
    if (params.options && params.options.available && (this.isUnavailable() || this.isPlatinum())) {
      return false;
    }

    if (
      params.options &&
      !params.options.premiums &&
      (this.isPremium() || this.isPlatinum() || this.isSedo())
    ) {
      return false;
    }

    if (params.range && this.price.price) {
      const [min, max] = params.range;
      if (!currency) {
        return false;
      }
      const price = currency.fromBase(this.price.price);
      if (price < min) {
        return false;
      }
      if (price > max) {
        return false;
      }
    }

    return true;
  }

  /**
   * Check whether the price should be shown for this domain.
   *
   * @return {Boolean}
   */
  shouldShowPrice() {
    if (!this.price.price) {
      return false;
    }

    return !this.isUnavailable() && !this.isFetching() && !this.isPlatinum();
  }

  /**
   * Check whether the regular price should be shown for this domain.
   *
   * @return {Boolean}
   */
  shouldShowRegular() {
    if (!this.price.regular || this.price.regular === this.price.price) {
      return false;
    }

    return (
      !this.isUnavailable() &&
      !this.isFetching() &&
      !this.isFinanced() &&
      !this.isPremium() &&
      !this.isPlatinum()
    );
  }

  /**
   * Check whether the renewal price should be shown for this domain.
   *
   * @return {Boolean}
   */
  shouldShowRenewal() {
    if (!this.price.renewal || this.price.price === this.price.renewal) {
      return false;
    }

    if (this.shouldShowRegular()) {
      return false;
    }

    if (
      this.isPremium() &&
      this.registry === "namecheap" &&
      [
        "host",
        "online",
        "press",
        "pw",
        "site",
        "store",
        "tech",
        "website",
        "music",
        "space",
        "fun",
      ].includes(this.tld)
    ) {
      return false;
    }

    return !this.isUnavailable() && !this.isFetching() && !this.isFinanced();
  }

  /**
   * Get the period inidicator for this domain (`/mo`, `/yr`, or none).
   *
   * @return {String}
   */
  getPeriod() {
    if (this.isFinanced()) {
      return "/mo";
    }
    return this.isYearly() ? "/yr" : "";
  }

  /**
   * Get the year when the domain was created.
   *
   * @return {Number|null}
   */
  getCreatedYear() {
    if (!!this.data && !!this.data.status && !!this.data.status.whois) {
      return this.data.status.whois.createdYear;
    }
    return null;
  }
}

export default Domain;
