import { getCookie, setCookie } from "utils/browser";

/**
 * The cookie where the user ID is stored.
 *
 * @type {String}
 */
const COOKIE_USER = "nc-user-id";

/**
 * Get a UUID stored in a cookie.
 *
 * If the cookie doesn't exist, a new one is created.
 *
 * Optionally, it's possible to set the cookie's max age. The value must be
 * informed in milliseconds.
 *
 * @param  {String} cookie
 * @param  {Number} [expires=null]
 * @param  {Number} [length=null]
 * @return {String}
 */
export const getUUID = (cookie, expires = null, length = 4) => {
  let id = getCookie(cookie);
  if (!id) {
    const base = Math.pow(10, length - 1);
    id = Math.floor(Math.random() * (9 * base)) + base;
    setCookie(cookie, id, expires);
  }
  return id;
};

/**
 * Get the user's browser fingerprint using the user ID.
 *
 * @return {String}
 */
export const getUserFingerprint = () => {
  const id = getUUID(COOKIE_USER);
  let bits = `${Math.random()}`;
  bits = bits.substring(bits.length - 9);
  return `${id}${bits}`;
};

/**
 * Check if the user is Authenticated based on cookie
 *
 * @return boolean
 */
export const isAuthenticated = () => {
  return getCookie(".ncauth") ? true : false;
};
