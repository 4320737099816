import React from "react";

/**
 * The search context.
 *
 * @type {React.Context}
 */
export const SearchContext = React.createContext();

/**
 * The notification context.
 *
 * @type {React.Context}
 */
export const NotificationContext = React.createContext();
