import axios from "utils/axios";
import { buildQuery } from "utils/browser";

/**
 * The websocket connection timeout.
 *
 * It's 5 minutes in ms.
 *
 * @type {Number}
 */
const TIMEOUT = 300000;

/**
 * The websocket states.
 *
 * @type {Object.<String, String>}
 */
const STATES = {
  connect: "connect",
  open: "open",
  closed: "closed",
};

/**
 * Check whether the app is running in a worker.
 *
 * @return {Boolean}
 */
/*global WorkerGlobalScope*/
/*eslint no-restricted-globals: ["error", "event"]*/
const isWorker = typeof WorkerGlobalScope !== "undefined" && self instanceof WorkerGlobalScope;

/**
 * The query class.
 *
 * It's used to query the status of domains.
 *
 * @author Gustavo Straube <gustavo@kettle.io>
 */
class Query {
  /**
   * The websocket instance.
   *
   * @type {WebSocket}
   * @private
   */
  _socket;

  /**
   * The timestamp for the last ping received from the websocket.
   *
   * @type {Number}
   * @private
   */
  _ping;

  /**
   * Indicates whether HTTP should be used instead of a websocket.
   *
   * @type {Boolean}
   * @private
   */
  _http = true;

  /**
   * The value to pass to the batch param of the websocket.
   *
   * @type {Boolean}
   * @private
   */
  _batch = false;

  /**
   * The current websocket state.
   *
   * @type {String}
   * @private
   */
  _state = STATES.closed;

  /**
   * The queued messages.
   *
   * @type {Array<Object>}
   * @private
   */
  _queued = [];

  /**
   * The completed messages.
   *
   * @type {Object.<String, Array>}
   * @private
   */
  _completed = {};

  /**
   * Create a new instance.
   *
   */
  constructor() {
    try {
      this._http = !(
        isWorker ||
        (window && "WebSocket" in window && window.WebSocket.CLOSING === 2)
      );
    } catch (e) {
      //
    }
  }

  /**
   * Run the status query for the given domains.
   *
   * The callback passed is executed for each domain.
   *
   * @param  {Array<String>} domains
   * @param  {Function} callback
   * @return {Promise}
   */
  async status(domains, callback) {
    const group = this._generateId();
    this._enqueue(group, domains);
    return new Promise((resolve, reject) => {
      try {
        let found = 0;
        const loop = setInterval(() => {
          if (!this._completed[group]) {
            return;
          }
          while (this._completed[group].length > 0) {
            const data = this._completed[group].shift();
            data.name = data.name.toLowerCase();
            const done = callback(data);
            if (done) {
              found++;
            }
          }
          if (found >= domains.length) {
            clearInterval(loop);
            return resolve();
          }
        }, 250);
      } catch (error) {
        return reject(error);
      }
    });
  }

  /**
   * Run the status query for the given domains through HTTP.
   *
   * @param  {Array<String>}  domains
   * @return {Promise<Array>}
   */
  async statusViaHttp(domains) {
    const params = {
      domains: typeof domains === "string" ? domains : domains.join(","),
      eppTimeoutMillis: 10000,
      deadlineMillis: 6000,
      eppNoCache: true,
      whois: true,
      trace: true,
    };
    if (process.env.REACT_APP_ENV === "production") {
      params.eppOnly = true;
    }
    const query = buildQuery(params);
    const url = process.env.REACT_APP_STATUS_HTTP + "/domainStatus?" + query;
    try {
      const response = await axios.get(url, { timeout: 20000 });
      return response.data.status.map(domain => {
        domain.name = domain.name.toLowerCase();
        return domain;
      });
    } catch (error) {
      if (error.code === "ECONNABORTED") {
        window.dispatchEvent(new CustomEvent("search.conn.issue"));
      }
      console.error(error);
      // return empty array if something goes wrong during loading process
      return [];
    }
  }

  /**
   * Run the aftermarket query for the given domains.
   *
   * The callback passed is executed for each domain.
   *
   * @param  {Array<String>} domains
   * @param  {Function} callback
   * @return {Promise}
   */
  async aftermarket(domains, callback) {
    const url =
      process.env.REACT_APP_AFTERMARKET +
      "/domain/status?domain=" +
      domains.filter(domain => !domain.endsWith(".fr")).join(",");
    let data = [];
    let ignoreDomains = [];

    try {
      const response = await axios.get(url);
      data = response.data.data.map(data => {
        data.domain = data.domain.toLowerCase();
        return data;
      });
    } catch (e) {
      console.error(e);
      const errors = (e.response.data && e.response.data.errors) || [];
      if (errors.length > 0) {
        errors
          .map(err => err.message)
          .filter(message => message.includes("is not a valid domain"))
          .map(message => message.replace(/[' (is not a valid domain)]/g, ""))
          .map(domain => {
            ignoreDomains.push(domain);
            data.push({ domain, status: "notfound" });
          });
      } else {
        domains.forEach(domain => {
          ignoreDomains.push(domain);
          data.push({ domain, status: "error" });
        });
      }
    }

    const fr = domains.filter(domain => domain.endsWith(".fr"));
    if (fr.length > 0) {
      fr.forEach(domain => data.push({ domain, status: "notfound" }));
    }

    if (ignoreDomains.length > 0) {
      let _domains = domains.filter(domain => !ignoreDomains.includes(domain));
      try {
        const url =
          process.env.REACT_APP_AFTERMARKET +
          "/domain/status?domain=" +
          _domains.filter(domain => !domain.endsWith(".fr")).join(",");
        const response = await axios.get(url);
        const _data = response.data.data.map(data => {
          data.domain = data.domain.toLowerCase();
          return data;
        });
        data = [...data, ..._data];
      } catch {
        _domains.forEach(domain => data.push({ domain, status: "notfound" }));
      }
    }

    data.forEach(callback);
  }

  /**
   * Run the premium query for the given domain.
   *
   * The callback passed is executed for the domain.
   *
   * @param  {String} domain
   * @param  {Function} callback
   * @return {Promise}
   */
  async premium(domain, callback) {
    try {
      const url = process.env.REACT_APP_PREMIUM + "/" + domain;
      const response = await axios.get(url);
      const data = response.data.data
        .map(data => {
          data.domain = data.domain.toLowerCase();
          return data;
        })
        .find(data => data.domain === domain);
      callback(data);
    } catch (e) {
      console.error(e);
      callback({
        domain,
      });
    }
  }

  /**
   * Force to close the websocket layer
   *
   * @return {void}
   */
  close() {
    if (this._socket) {
      try {
        // change the ping time to force the socket close without reconnection
        this._ping = new Date().getTime() + (TIMEOUT + 60000);
        this._socket.close();
      } catch (e) {
        //
      }
    }
  }

  /**
   * Connect or reconnect the websocket layer.
   *
   * @param  {Boolean} [batch=false]
   * @return {void}
   */
  conn(batch = false) {
    if (this._http) {
      return;
    }
    this._batch = batch;

    if (this._socket) {
      try {
        this._socket.close();
      } catch (e) {
        this._connect();
      }
    }
  }

  /**
   * Connect to the websocket.
   *
   * @return {void}
   * @private
   */
  _connect() {
    const params = {
      batch: this._batch,
      whois: true,
      trace: true,
    };
    const query = buildQuery(params);
    this._socket = new WebSocket(process.env.REACT_APP_STATUS_WS + "?" + query);

    /**
     * Send all pending message when the connection opens.
     *
     * @return {void}
     */
    this._socket.addEventListener("open", () => {
      this._state = STATES.open;
      while (this._queued.length > 0) {
        this._send(this._queued.shift());
      }
    });

    /**
     * Store a received message on the completed stack.
     *
     * @param {Object} message
     * @return {void}
     */
    this._socket.addEventListener("message", event => {
      const data = JSON.parse(event.data);
      this._complete(data.reqID, data.data);
    });

    /**
     * Reopen the connection when it's closed.
     *
     * @return {void}
     */
    this._socket.addEventListener("close", () => {
      this._state = STATES.closed;
      if (Date.now() - this._ping < TIMEOUT) {
        this._connect();
      }
    });

    /**
     * Fallback to HTTP when the websocket fails.
     *
     * @return {void}
     */
    this._socket.addEventListener("error", () => {
      this._http = true;
      this._handleError();
    });
  }

  /**
   * Handle socket errors.
   *
   * @return {void}
   * @private
   */
  _handleError() {
    if (this._queued.length === 0) {
      return;
    }

    this._queued.forEach(message => {
      this._send(message);
    });
  }

  /**
   * Add a list of domains as a message to the queue.
   *
   * @param  {String} group
   * @param  {Array<String>} domains
   * @return {void}
   * @private
   */
  _enqueue(group, domains) {
    this._send({
      type: "domainStatus",
      reqID: group,
      data: { domains },
    });
  }

  /**
   * Send a message through the socket.
   *
   * @param  {Object} message
   * @return {void}
   * @private
   */
  _send(message) {
    if (this._http) {
      const domains = message.data.domains.join(",");
      this.statusViaHttp(domains).then(status => {
        status.forEach(data => {
          this._complete(message.reqID, data);
        });
      });
      return;
    }

    if (this._state === STATES.closed) {
      this._state = STATES.connect;
      this._connect();
    }

    if (this._socket.readyState !== WebSocket.OPEN) {
      this._queued.push(message);
      return;
    }

    this._socket.send(JSON.stringify(message));
    this._ping = Date.now();
  }

  /**
   * Add the given data to the completed data.
   *
   * @param  {String} group
   * @param  {Object} data
   * @return {void}
   * @private
   */
  _complete(group, data) {
    if (!this._completed[group]) {
      this._completed[group] = [];
    }
    this._completed[group].push(data);
  }

  /**
   * Generate a random ID.
   *
   * @return {String}
   * @private
   */
  _generateId() {
    return [Math.floor(Date.now() / 1000), Math.floor(Math.random() * 99)].join("");
  }
}

export default new Query();
