import React, { useContext, lazy, Suspense } from "react";

import { observer } from "mobx-react-lite";

import StandardModeWireframe from "components/StandardMode/wireframe";
import { SearchContext } from "utils/context";
const StandardMode = lazy(() =>
  import(/* webpackChunkName: "StandardModeComponent" */ "components/StandardMode")
);

/**
 * Search component.
 *
 * Renders the search form.
 *
 * @param  {String} [defaultQuery=null]
 * @return {React.FunctionComponentElement}
 * @author Gustavo Straube <gustavo@kettle.io>
 */
const Search = ({ defaultQuery = null }) => {
  const search = useContext(SearchContext);

  return (
    <div>
      {!search.isGenerator() && !search.isBeast() && !search.isAuctions() && (
        <Suspense fallback={<StandardModeWireframe />}>
          <StandardMode defaultQuery={defaultQuery} />
        </Suspense>
      )}
    </div>
  );
};

export default observer(Search);
