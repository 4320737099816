import axios from "utils/axios";
import { trackCart, trackEvent, trackFail } from "utils/backend";
import { getCookie } from "utils/browser";
import query from "utils/query";
import { asyncForEach } from "./functions";
import { splitDomain } from "store/Search/utils";
import { logReward } from "utils/experiment";
import {
  DURATION_TYPES,
  EASY_WP_PLANS,
  getRelatePlan,
  isLimitedInCart,
  RELATE_PLANS,
} from "./product";

/**
 * Get a unique identifier.
 *
 * The unique identifier is simply the current date formatted as
 * `YYYYMMDDHHmmssSSS`.
 *
 * Plain JS is used here to avoid importing another library (like **moment**)
 * for such simple operation.
 *
 * @return {String}
 */
const _getUid = () => {
  const now = new Date();
  return [
    now.getFullYear(),
    now.getMonth() + 1,
    now.getDate(),
    now.getHours(),
    now.getMinutes(),
    now.getSeconds(),
    ("" + now.getMilliseconds()).padStart(3, "0"),
  ]
    .map(part => (("" + part).length < 2 ? "0" + part : part))
    .join("");
};

/**
 * Get a domain name by its cart ID.
 *
 * @param  {String} id
 * @param  {Array<Object>} items
 * @return {String|null}
 */
const _getDomainById = (id, items) => {
  const item = items.find(item => {
    return item.ProductType === "DOMAINS" && item.Id === id;
  });
  return item ? item.Description.toLowerCase() : null;
};

const _getCartItemDuration = duration => {
  const { Value: value, DurationType } = duration;
  const interval = DURATION_TYPES[DurationType];

  return value === 1 ? interval : `${value}${interval}`;
};

/**
 * Sleeps for a second and return the response.
 *
 * @param  {any} response
 * @return {Promise<any>}
 */
export const _sleep = response => {
  return new Promise(resolve => {
    setTimeout(() => {
      return resolve(response);
    }, 1000);
  });
};

/**
 * Get the params for adding a domain to cart.
 *
 * @param  {Domain} domain
 * @return {Object}
 */
const _getDomainParams = domain => {
  const uid = _getUid();

  if (domain.isRegistryPremium()) {
    return {
      Key: `${uid}:${domain.id}`,
      Product: domain.tld,
      Action: "Register",
      Duration: {
        Value: 1,
        DurationType: "YEAR",
      },
    };
  }

  if (domain.registry === "namecheap") {
    return {
      Key: `${uid}:${domain.id}`,
      Product: "ncmarketplace",
      Action: "PURCHASE",
      Duration: {
        Value: 1,
        DurationType: "YEAR",
      },
      Qty: 1,
      Enabled: true,
    };
  }

  if (domain.registry === "afternic" || domain.registry === "sedo") {
    return {
      Key: `${uid}:aftermarket=True:${domain.id}`,
      Product: "aftermarketmarketplace",
      Action: "PURCHASE",
      Duration: {
        Value: 1,
        DurationType: "YEAR",
      },
      Qty: 1,
      Enabled: true,
    };
  }

  const params = {
    Key: `${uid}:${domain.id}`,
    Product: domain.tld,
    Action: "Register",
    Options: {
      __type: "Nc:ShoppingCart:Dto:CartItemDomainRegisterOption",
      Check: domain.enable_eap,
    },
  };

  if (domain.tld !== "org") {
    params.Duration = {
      Value: 1,
      DurationType: "YEAR",
    };
  }

  return params;
};

/**
 * Get the duration based on the given plan.
 *
 * If the plan is empty or doesn't have a duration, defaults to the object
 * passed in `def` argument.
 *
 * @param  {Object} plan
 * @param  {Object} def
 * @return {Object}
 */
const _getDuration = (plan, def) => {
  if (!!plan && !!plan.duration) {
    return {
      Value: plan.duration.amount,
      DurationType: plan.duration.type === "yr" ? "YEAR" : "MONTH",
    };
  }
  return def;
};

/**
 * Get an item given the cart slot ID of a Marketplace app.
 *
 * @param  {Object} cartItem
 * @return {Object|null}
 */
const _getMarketplaceProduct = cartItem => {
  const productId = cartItem.Options ? cartItem.Options.ProductId : null;
  switch (productId) {
    case 251: {
      const interval = _getCartItemDuration(cartItem.Duration);

      return {
        id: "gsuite",
        slug: "gsuite",
        domain: cartItem.ParentKey !== "" ? cartItem.Options.DomainName : null,
        cartId: cartItem.Key,
        price: cartItem.Price,
        variant: `gsuite-${interval}`,
        prpSlug: `gsuite-${interval}`,
      };
    }
    case 252: {
      const plan = EASY_WP_PLANS[cartItem.PricingPlanId];
      const interval = _getCartItemDuration(cartItem.Duration);

      return {
        id: "easy-wp",
        slug: "easy-wp",
        domain: cartItem.ParentKey !== "" ? cartItem.Options.DomainName : null,
        cartId: cartItem.Key,
        price: cartItem.Price,
        variant: plan === "starter-trial" ? `ewp-${plan}` : `ewp-${plan}-${interval}`,
        prpSlug: `ewp-${plan}-${interval}`,
      };
    }
    case 2530: {
      const interval = _getCartItemDuration(cartItem.Duration);

      return {
        id: "vpn",
        slug: "vpn",
        cartId: cartItem.Key,
        price: cartItem.Price,
        variant: `vpn-${interval}`,
        prpSlug: `vpn-${interval}`,
      };
    }
    case 254: {
      return {
        id: "logo-maker",
        slug: "logo-maker",
        variant: "logo-maker",
        prpSlug: "logo-maker",
        cartId: cartItem.Key,
        price: cartItem.Price,
      };
    }
    case 255: {
      return {
        id: "business-card-maker",
        slug: "business-card-maker",
        variant: "business-card-maker",
        prpSlug: "business-card-maker",
        cartId: cartItem.Key,
        price: cartItem.Price,
      };
    }
    case 2580: {
      const plan = RELATE_PLANS[cartItem.PricingPlanId];
      const isSocial = cartItem.Title.toLowerCase().includes("social");
      const isReviews = cartItem.Title.toLowerCase().includes("reviews");
      const interval = _getCartItemDuration(cartItem.Duration);
      let slug = "";

      if (isSocial) {
        slug = "social-media-manager";
      }

      if (isReviews) {
        slug = "review-manager";
      }

      return {
        id: slug,
        slug: slug,
        cartId: cartItem.Key,
        price: cartItem.Price,
        variant: interval === "mo" ? plan : `${plan}-${interval}`,
        prpSlug: `relate-${plan}-${interval}`,
      };
    }
    case 2600:
    case 260: {
      return {
        id: "sitelock",
        slug: "sitelock",
        variant: "sitelock",
        prpSlug: "sitelock",
        cartId: cartItem.Key,
        price: cartItem.Price,
      };
    }
    default:
      return null;
  }
};

/**
 * Get the params for adding a product to cart.
 *
 * @param  {String} id
 * @param  {Object} [domainData=null]
 * @param  {Number|Object} [plan_id=null]
 * @param  {Boolean} [free=false]
 * @param  {String} [interval=null]
 * @param  {Number} [durationValue=1]
 * @return {Object}
 */
const _getProductParams = (
  id,
  domainData = null,
  plan = null,
  free = false,
  interval = null,
  durationValue = 1
) => {
  const domain = domainData ? domainData.id : null;
  const domainCartKey = domainData ? domainData.cartKey : null;
  const uid = _getUid();
  let product = !!plan && !!plan.id ? plan.id : id;
  if (id.includes("stellar")) {
    const location =
      "server-location-" + (!!plan && !!plan.extra && plan.extra.name === "UK" ? "uk" : "us");

    return {
      Items: [
        {
          Key: `${uid}:${domain}:${product}`,
          ParentKey: `${uid}:${domain}`,
          Product: product,
          Action: "PURCHASE",
          Duration: _getDuration(plan, {
            Value: durationValue,
            DurationType: interval === "yr" ? "YEAR" : "MONTH",
          }),
        },
        {
          Key: `${uid}:${domain}:${product}:${location}`,
          ParentKey: `${uid}:${domain}:${product}`,
          Product: location,
          Action: "PURCHASE",
        },
      ],
    };
  }

  if (["ox-private", "ox-starter", "ox-universal"].includes(id)) {
    let mailboxes = 1;
    if ((product === "ox-pro") | "ox-amplified") {
      mailboxes = 3;
    }
    if ((product === "ox-ultimate") | "ox-absolute") {
      mailboxes = 5;
    }
    return {
      Items: [
        {
          Key: `${uid}:${domain}:${product}`,
          ParentKey: `${uid}:${domain}`,
          Product: product,
          Action: "PURCHASE",
          Duration: free
            ? {
                Value: 2,
                DurationType: "MONTH",
              }
            : _getDuration(plan, {
                Value: durationValue,
                DurationType: interval === "yr" ? "YEAR" : "MONTH",
              }),
          Options: {
            __type: "Nc:ShoppingCart:Dto:Options:CartItemOXHostingPurchaseOption",
            DomainType: "SHOPPINGCART",
            IgnoreQty: mailboxes,
            CreateLater: true,
            IsTrial: true,
            IsAddonAdded: true,
            MailBoxCount: mailboxes,
            ConfigureMailBox: false,
            IsTermsAndConditionsAgreed: true,
            DomainName: domain,
          },
        },
      ],
    };
  }

  if (id === "ssl") {
    const duration = _getDuration(plan, {
      Value: durationValue,
      DurationType: interval === "mo" ? "MONTH" : "YEAR",
    });

    if (product === id) {
      product = "positivessl";
    }

    if (!domain) {
      return {
        Items: [
          {
            Key: `${uid}:${product}`,
            Product: product,
            Action: "PURCHASE",
            Duration: duration,
          },
        ],
      };
    }

    if (product !== "positivessl" || duration.Value !== 1) {
      return {
        Items: [
          {
            Key: `${uid}:${product}`,
            ParentKey: `${domainCartKey || uid}:${domain}`,
            Product: product,
            Action: "PURCHASE",
            Duration: duration,
          },
        ],
      };
    }

    return {
      Key: `${domainCartKey || uid}:${domain}:positivessl`,
      BonusProductType: "ssl",
    };
  }

  if (id === "easy-wp") {
    let plan_id = 121;
    if (!!plan && !!plan.plan_id) {
      plan_id = plan.plan_id;
    }
    if (!domain) {
      return {
        Items: [
          {
            Key: `appstore|2520|${plan_id}|${uid}`,
            Product: "appstore",
            Action: "PURCHASE",
            Duration: _getDuration(plan, {
              Value: durationValue,
              DurationType: interval === "mo" ? "MONTH" : "YEAR",
            }),
            PricingPlanId: plan_id,
          },
        ],
      };
    }

    return {
      Items: [
        {
          Key: `${domain}:appstore|252|${domain}`,
          ParentKey: `${uid}:${domain}`,
          Product: "appstore",
          Action: "PURCHASE",
          Duration: _getDuration(plan, {
            Value: durationValue,
            DurationType: interval === "mo" ? "MONTH" : "YEAR",
          }),
          Options: null,
          PricingPlanId: plan_id,
        },
      ],
    };
  }

  if (id === "vpn") {
    let plan_id = 231;
    if (interval === "yr") {
      plan_id = 223;
    }
    if (interval === "mo") {
      plan_id = 231;
    }
    if (interval === "yr" && durationValue === 3) {
      plan_id = 224;
    }
    if (!!plan && ("" + plan).match(/^\d+$/)) {
      plan_id = plan;
    }
    return {
      Items: [
        {
          Key: `appstore|2530|${plan_id}|${uid}`,
          Product: "appstore",
          Action: "PURCHASE",
          Duration: _getDuration(plan, {
            Value: durationValue,
            DurationType: interval === "yr" ? "YEAR" : "MONTH",
          }),
          PricingPlanId: plan_id,
        },
      ],
    };
  }

  if (id === "logo-maker") {
    if (!domain) {
      return {
        Items: [
          {
            Key: `appstore|2540|321|${uid}`,
            Product: "appstore",
            Action: "PURCHASE",
            Duration: _getDuration(plan, {
              Value: durationValue,
              DurationType: interval === "mo" ? "MONTH" : "YEAR",
            }),
            PricingPlanId: 321,
          },
        ],
      };
    }

    return {
      Items: [
        {
          Key: `${domain}:appstore|254|${domain}`,
          ParentKey: `${uid}:${domain}`,
          Product: "appstore",
          Action: "PURCHASE",
          Duration: _getDuration(plan, {
            Value: durationValue,
            DurationType: interval === "mo" ? "MONTH" : "YEAR",
          }),
          Options: null,
          PricingPlanId: 321,
        },
      ],
    };
  }

  if (id === "business-card-maker") {
    if (!domain) {
      return {
        Items: [
          {
            Key: `appstore|2550|421|${uid}`,
            Product: "appstore",
            Action: "PURCHASE",
            Duration: _getDuration(plan, {
              Value: durationValue,
              DurationType: interval === "mo" ? "MONTH" : "YEAR",
            }),
            PricingPlanId: 421,
          },
        ],
      };
    }

    return {
      Items: [
        {
          Key: `${domain}:appstore|255|${domain}`,
          ParentKey: `${uid}:${domain}`,
          Product: "appstore",
          Action: "PURCHASE",
          Duration: _getDuration(plan, {
            Value: durationValue,
            DurationType: interval === "mo" ? "MONTH" : "YEAR",
          }),
          Options: null,
          PricingPlanId: 421,
        },
      ],
    };
  }

  if (id === "gsuite") {
    return {
      Items: [
        {
          Key: `${domain}:appstore|251|${domain}`,
          ParentKey: `${uid}:${domain}`,
          Product: "appstore",
          Action: "PURCHASE",
          Duration: {
            Value: durationValue,
            DurationType: interval === "yr" ? "YEAR" : "MONTH",
          },
          Options: null,
          PricingPlanId: interval === "yr" ? 64 : 14,
        },
      ],
    };
  }

  if (id.includes("premium-dns")) {
    return {
      Items: [
        {
          Key: `${uid}:${domain}:${product}`,
          ParentKey: `${uid}:${domain}`,
          Product: product,
          Action: "PURCHASE",
          Duration: {
            Value: durationValue,
            DurationType: interval === "yr" ? "YEAR" : "MONTH",
          },
        },
      ],
    };
  }

  if (id.includes("social-media-manager")) {
    const variant = [plan.id, interval, free ? "free" : ""].join("-");
    const PricingPlanId = getRelatePlan(variant);
    const appId = process.env.REACT_APP_ENV === "production" ? "2580" : "2580";
    const productId = process.env.REACT_APP_ENV === "production" ? "10901" : "10901";

    return {
      Items: [
        {
          Key: `appstore|${appId}|${productId}|${uid}`,
          Product: "appstore",
          Action: "PURCHASE",
          Duration: _getDuration(plan, {
            Value: durationValue,
            DurationType: interval === "mo" ? "MONTH" : "YEAR",
          }),
          PricingPlanId: PricingPlanId,
        },
      ],
    };
  }

  if (id.includes("review-manager")) {
    const PricingPlanId = process.env.REACT_APP_ENV === "production" ? 7240 : 7240;
    const appId = process.env.REACT_APP_ENV === "production" ? "2580" : "2580";
    const productId = process.env.REACT_APP_ENV === "production" ? "724" : "724";

    return {
      Items: [
        {
          Key: `appstore|${appId}|${productId}|${uid}`,
          Product: "appstore",
          Action: "PURCHASE",
          Duration: _getDuration(plan, {
            Value: durationValue,
            DurationType: interval === "mo" ? "MONTH" : "YEAR",
          }),
          PricingPlanId: PricingPlanId,
        },
      ],
    };
  }

  if (id.includes("stencil")) {
    const PricingPlanId = 10908;
    const appId = 11449;
    const productId = 5932;
    return {
      Items: [
        {
          Key: `appstore|${appId}|${productId}|${uid}`,
          Product: "appstore",
          Action: "PURCHASE",
          Duration: {
            Value: durationValue,
            DurationType: interval === "mo" ? "MONTH" : "YEAR",
          },
          PricingPlanId: PricingPlanId,
        },
      ],
    };
  }

  if (id === "sitelock") {
    const PricingPlanId = 925;

    if (!domain) {
      return {
        Items: [
          {
            Key: `appstore|2600|${PricingPlanId}|${uid}`,
            Product: "appstore",
            Action: "PURCHASE",
            Duration: _getDuration(plan, {
              Value: durationValue,
              DurationType: interval === "mo" ? "MONTH" : "YEAR",
            }),
            PricingPlanId: PricingPlanId,
          },
        ],
      };
    }

    return {
      Items: [
        {
          Key: `${domain}:appstore|260|${domain}`,
          ParentKey: `${uid}:${domain}`,
          Product: "appstore",
          Action: "PURCHASE",
          Duration: _getDuration(plan, {
            Value: durationValue,
            DurationType: interval === "mo" ? "MONTH" : "YEAR",
          }),
          Options: null,
          PricingPlanId: PricingPlanId,
        },
      ],
    };
  }

  if (id === "seo-manager") {
    const PricingPlanId = 10240;

    return {
      Items: [
        {
          Key: `${domain}:appstore|261|${domain}`,
          ParentKey: `${uid}:${domain}`,
          Product: "appstore",
          Action: "PURCHASE",
          Duration: _getDuration(plan, {
            Value: durationValue,
            DurationType: interval === "mo" ? "MONTH" : "YEAR",
          }),
          Options: null,
          PricingPlanId: PricingPlanId,
        },
      ],
    };
  }

  return {};
};

/**
 * Get the product name based on its ID and the plan.
 *
 * @param  {String} id
 * @param  {Object} plan
 * @return {String}
 */
const _getProductName = (id, plan = null) => {
  if (!plan) {
    return id;
  }

  if (id.match(/^ox-/)) {
    return plan.id.replace(/^ox/, "pe").replace(/-/g, "_");
  }

  if (!!plan.duration && !!plan.duration.type) {
    return plan.id.replace(/-/g, "_") + "_" + plan.duration.type;
  }

  return id;
};

/**
 * Get the base URL for cart API.
 *
 * @returns {String}
 */
const _getBaseUrl = () =>
  process.env.NODE_ENV === "development" ? "http://localhost:8000" : window.location.origin;

/**
 * Send request to cart API for adding a domain to cart.
 *
 * @param  {Domain} domain
 * @return {Promise<Boolean|String>}
 */
const _callApi = async domain => {
  try {
    const item = _getDomainParams(domain);

    const baseUrl = _getBaseUrl();
    const url = `${baseUrl}/api/v1/ncpl/cart/user/additems`;
    const response = await axios.post(
      url,
      {
        Items: [item],
      },
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-Requested-With": "XMLHttpRequest",
          "x-ncpl-rcsrf": getCookie("x-ncpl-csrf"),
        },
      }
    );
    const type = response.data[0].MessageKey;
    if (type === "ADDED") {
      return item;
    }
    return type;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * Check whether the response from cart API contains an error.
 *
 * @param  {Object} response
 * @return {Boolean}
 */
const _hasError = response => {
  try {
    const data = Array.isArray(response.data) ? response.data[0] : response.data;
    return !!data.MessageKey.match(/error/i);
  } catch (e) {
    console.error(e);
  }

  return true;
};

/**
 * Parse products in cart
 *
 * @param {Object} item
 * @param {string} parent
 */
const _mapProduct = async (item, parent) => {
  if (!item.Enabled || !item.ProductTypeType) return null;

  if (item.ProductTypeType.trim().toLowerCase() === "ssl") {
    return {
      id: "ssl",
      domain: parent || item.Options.DomainName,
      cartId: item.Key,
      price: item.Price,
      variant: "positive-ssl",
      prpSlug: "positivessl_yr",
    };
  }

  if (item.ProductTypeType.trim().toLowerCase() === "premiumdns") {
    return {
      id: "premium-dns",
      domain: parent,
      cartId: item.Key,
      price: item.Price,
      variant: "premium-dns",
      prpSlug: "premium-dns",
    };
  }

  if (item.ProductTypeType.trim().toLowerCase() === "oxhosting") {
    return {
      id: item.Product === "ox-private" ? item.Product : "ox-starter",
      domain: parent || item.Options.DomainName,
      cartId: item.Key,
      price: item.Price,
      variant: item.Product,
      prpSlug: item.Product,
    };
  }

  if (item.ProductTypeType.trim().toLowerCase() === "hosting") {
    const interval = _getCartItemDuration(item.Duration);
    return {
      id: item.Product,
      domain: parent || item.Options.DomainName,
      cartId: item.Key,
      price: item.Price,
      variant: `${item.Product}-${interval}`,
      prpSlug: `${item.Product}-${interval}`,
    };
  }

  if (item.ProductTypeType.trim().toLowerCase() === "appstore") {
    const product = await _getMarketplaceProduct(item);
    if (product) {
      return product;
    }
  }

  return null;
};

/**
 * Map products from cart response
 *
 * @param {Array<Object>} items
 * @param {string} parent
 * @return {Promise<Array<Object>>}
 */
const _mapProducts = async (items, parent = null) => {
  const products = [];
  await items.forEach(async item => {
    const product = await _mapProduct(item, parent);
    if (product) {
      products.push(product);
    }
  });

  return products;
};

/**
 * Get the current items in the user cart.
 *
 * The returned promise resolves to an `object` containg a list of `domains` and
 * `products`.
 *
 * @return {Promise<Object.<String, Array>>}
 */
export const getCart = async () => {
  const cart = {
    domains: [],
    products: [],
  };

  const baseUrl = _getBaseUrl();
  const url = `${baseUrl}/cart/json/operation.aspx/GetALLIteminCart`;
  let response;
  try {
    response = await axios.post(url, {}, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest",
        "x-ncpl-rcsrf": getCookie("x-ncpl-csrf"),
      },
    });
  } catch (error) {
    console.error(error);
    return cart;
  }

  if (!response.data || !response.data.d || !response.data.d.Cartitems) {
    return cart;
  }

  const items = response.data.d.Cartitems;

  items.forEach(item => {
    if (item.ProductType === "DOMAINS" || item.ProductType === "MARKETPLACE") {
      cart.domains.push({
        domain: item.Description.toLowerCase(),
        cartId: item.Id,
      });
      return;
    }
    if (item.ProductType === "SSL") {
      cart.products.push({
        id: "ssl",
        domain: _getDomainById(item.ParentItemId, items),
        cartId: item.Id,
      });
      return;
    }
    if (item.ProductType === "PREMIUMDNS") {
      cart.products.push({
        id: "premium-dns",
        domain: item.Slotid,
        cartId: item.Id,
      });
      return;
    }
    if (item.ProductType === "OXHOSTING") {
      cart.products.push({
        id: item.ProductName === "ox-private" ? item.ProductName : "ox-starter",
        domain: item.Slotid,
        cartId: item.Id,
      });
      return;
    }
    if (item.ProductType === "HOSTING") {
      cart.products.push({
        id: item.ProductName,
        domain: item.Slotid,
        cartId: item.Id,
      });
      return;
    }
    if (
      item.ProductType === "APPSTORE" &&
      item.Slotid.match(/^appstore(-domainless)?\|(120|121|122|123|124|125|252)/)
    ) {
      cart.products.push({
        id: "easy-wp",
        domain: item.Slotid.replace(/.*\|([^|]+)$/, "$1"),
        cartId: item.Id,
      });
      return;
    }
    if (item.ProductType === "APPSTORE" && item.Slotid.indexOf("appstore-domainless|2530") === 0) {
      cart.products.push({
        id: "vpn",
        cartId: item.Id,
      });
      return;
    }
    if (item.ProductType === "APPSTORE" && item.Slotid.match(/^appstore(-domainless)?\|254/)) {
      cart.products.push({
        id: "logo-maker",
        domain: item.Slotid.replace(/.*\|([^|]+)$/, "$1"),
        cartId: item.Id,
      });
      return;
    }
    if (item.ProductType === "APPSTORE" && item.Slotid.match(/^appstore(-domainless)?\|255/)) {
      cart.products.push({
        id: "business-card-maker",
        domain: item.Slotid.replace(/.*\|([^|]+)$/, "$1"),
        cartId: item.Id,
      });
      return;
    }
    if (item.ProductType === "APPSTORE" && item.Slotid === "appstore-domainless|2580|7220") {
      cart.products.push({
        id: "social-media-manager",
        cartId: item.Id,
      });
      return;
    }
    if (
      item.ProductType === "APPSTORE" &&
      (item.Slotid === "appstore-domainless|2580|724" ||
        item.Slotid === "appstore-domainless|2580|7240")
    ) {
      cart.products.push({
        id: "review-manager",
        cartId: item.Id,
      });
      return;
    }
  });

  cart.products = cart.products.map(product => {
    if (!product.domain || !product.domain.includes(".")) {
      product.domain = null;
    }
    return product;
  });

  return cart;
};

/**
 * Get the current items in the user cart with details
 *
 * @return {Promise<Object.<String, Array>>}
 */
export const getCartDetails = async () => {
  const cart = {
    domains: [],
    products: [],
  };

  const baseUrl = _getBaseUrl();
  const url = `${baseUrl}/api/v1/ncpl/cart/user/getshoppingcartnew`;
  let response;
  try {
    response = await axios.get(url, null, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest",
        "x-ncpl-rcsrf": getCookie("x-ncpl-csrf"),
      },
    });
  } catch (error) {
    console.error(error);
    return cart;
  }

  if (!response.data || !response.data.CartItems) {
    return cart;
  }

  const items = response.data.CartItems;
  await asyncForEach(items, async item => {
    if (!item.Enabled) return;

    if (item.ProductTypeType === "marketplace" || item.ProductTypeType === "domains") {
      const [sld, tld] = splitDomain(item.Options.DomainName);
      cart.domains.push({
        cartId: item.Key,
        name: item.Options.DomainName,
        domain: item.Options.DomainName,
        sld: sld,
        tld: tld,
        seller: item.Options.SellerUserName || null,
        price: item.Price,
        AdditionalCost: item.AdditionalCost,
        priceTotal: item.Price + item.AdditionalCost,
        premium: item.IsPremium,
      });

      if (item.Children.length > 0) {
        const products = await _mapProducts(item.Children, item.Options.DomainName);
        products.forEach(product => cart.products.push(product));
      }
      return;
    }

    const product = await _mapProduct(item);
    if (product) {
      cart.products.push(product);
    }
  });

  cart.products = cart.products.map(product => {
    if (!product.domain || !product.domain.includes(".")) {
      product.domain = null;
    }
    return product;
  });

  return cart;
};

/**
 * Get the shopping cart summary
 *
 * @return {Object|null}
 */
export const getCartSummary = async (full = false) => {
  let details = {};
  try {
    const baseUrl = _getBaseUrl();
    const url = `${baseUrl}/api/v1/ncpl/cart/user/getshoppingcartsummary?finalTotalOnly=false`;
    const config = {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest",
        "x-ncpl-rcsrf": getCookie("x-ncpl-csrf"),
      },
    };
    const response = await axios.get(url, config);

    if (full) {
      details = await axios.get(`${baseUrl}/api/v1/ncpl/cart/user/getshoppingcartnew`, config);
    }

    return {
      ...response.data,
      ...details.data,
    };
  } catch (error) {
    console.error(error);
    return {};
  }
};

/**
 * Add a domain to cart.
 *
 * @param  {Domain} domain
 * @param  {Boolean} [beast=false]
 * @param  {Boolean} [bulk=false]
 * @return {Promise<Boolean>}
 */
export const addDomain = async (domain, beast = false, bulk = false) => {
  if (!domain.isReady()) {
    return false;
  }

  domain.setPurchasing();

  if (domain.price.isNormal) {
    let canAdd = false;
    try {
      const status = (await query.statusViaHttp([domain.id])).shift();
      canAdd =
        status.name === domain.id &&
        (status.error === "non-direct tld" || (status.available && !status.premium));
    } catch (e) {
      console.error(e);
    }

    if (!canAdd) {
      trackFail(domain.id).catch(console.error);
      console.error("Tried to add premium domain with regular price to cart");
      window.alert(`${domain.id} couldn't be added to cart.`);
      domain.setReady(); // Roll back to previous state
      return false;
    }
  }

  const response = await _callApi(domain);

  if (response === false) {
    domain.setReady(); // Roll back to previous state
    return false;
  }

  if (typeof response === "string") {
    domain.setReady(); // Roll back to previous state
    domain.error = response;
    return false;
  }

  if (typeof response === "object") {
    const uid = response.Key.split(":").shift();
    domain.cartKey = uid;
  }

  if (beast) {
    // ignore beast actions
  }

  if (!bulk) {
    document.dispatchEvent(new CustomEvent("domainSearch.addToCart", { detail: domain.id }));
  }

  // domain.setPurchased();
  return true;
};

/**
 * Add a product to cart.
 *
 * The returned promise resolves to a `String` if the product couldn't be added
 * to cart. Else, it resolves to a `Boolean`.
 *
 * @param  {Object} product
 * @param  {String} [domain=null]
 * @param  {Boolean} [pick=false]
 * @param  {Number|Object} [plan=null]
 * @param  {Object} [reward=null]
 * @return {Promise<Boolean|String>}
 */
export const addProduct = async (
  product,
  domain = null,
  pick = false,
  plan = null,
  reward = null
) => {
  const id = product.slug;

  if (isLimitedInCart(id)) {
    const cart = await getCart();
    if (cart.products.find(product => product.id === id)) {
      return true; // Already added, won't add again
    }
  }

  const eventLabel = "Add Product " + (pick ? "Suggestion" : "") + " To Cart";
  const productName = _getProductName(id, plan);
  const interval = product.interval;
  const durationValue = product.durationValue;
  const freeTrial = product.free_trial || false;
  const refId = await getRefId();

  trackCart(domain ? domain.id : null, productName, pick, false, refId).catch(console.error);
  trackEvent(eventLabel, productName);

  try {
    const action =
      id === "ssl" &&
      !!domain &&
      !(!!plan && !!plan.id && (plan.id !== "positivessl" || plan.duration.amount !== 1))
        ? "addBonusToShoppingCartItem"
        : "additems";
    const baseUrl = _getBaseUrl();
    const url = `${baseUrl}/api/v1/ncpl/cart/user/${action}`;

    const response = await axios.post(
      url,
      _getProductParams(id, domain, plan, freeTrial, interval, durationValue),
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-Requested-With": "XMLHttpRequest",
          "x-ncpl-rcsrf": getCookie("x-ncpl-csrf"),
        },
      }
    );

    if (response.status === 200 && !_hasError(response)) {
      document.dispatchEvent(
        new CustomEvent("domainSearch.addProductToCart", {
          detail: {
            slug: id,
            name: product.name,
          },
        })
      );

      if (reward) {
        const refId = await getRefId();
        await logReward(reward.slug, reward.decisionId, refId);
      }
      return true;
    }

    return "We're sorry but we couldn't add this product to the cart.";
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * Get the RefId from Cart API
 *
 * @return {Promise<String>}
 */
const _getRefId = (async () => {
  const baseUrl = _getBaseUrl();
  const config = {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-Requested-With": "XMLHttpRequest",
      "x-ncpl-rcsrf": getCookie("x-ncpl-csrf"),
    },
  };
  const details = await axios.get(`${baseUrl}/api/v1/ncpl/cart/user/refid`, config);
  return details.data || _getUid();
})();

export const getRefId = () => _getRefId;
