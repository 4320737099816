import React from "react";
import ReactDOM from "react-dom";

import App from "containers/App";

import { configure } from "mobx";
import { init as initSentry } from "@sentry/react";

import { loadScript } from "utils/browser";

import "index.scss";

// const banditUrl = process.env.NODE_ENV === 'production'
//     ? 'https://gradient-app-bucket-public.s3.amazonaws.com/snippets/v0.1/banditMl.min.js'
//     : 'https://gradient-app-bucket-public-staging.s3.amazonaws.com/snippets/banditMl.min.js';
const typeformUrl = "https://embed.typeform.com/embed.js";

if (process.env.REACT_APP_ENV === "production") {
  initSentry({
    dsn: "https://fc00088e6caa44e69404983907919edc@o18766.ingest.sentry.io/94166",
    // Enable sentry only for the search js app
    allowUrls: ["https://d1dijnkjnmzy2z.cloudfront.net"],
  });
}

configure({ isolateGlobalState: true });

configure({ isolateGlobalState: true });

const render = () => {
  ReactDOM.render(<App />, document.getElementById("react-nc-search"));
};

Promise.all([
  // loadScript(banditUrl, 'search-bandit'),
  loadScript(typeformUrl, "search-typeform"),
])
  .then(() => {
    render();
  })
  .catch(console.error);

window.loadSearchForTab = function () {};
