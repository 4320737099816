/**
 * Browser utilities.
 *
 * @author Gustavo Straube <gustavo@kettle.io>
 */

/**
 * Check whether the given cookie has a truthy value, i.e. literal boolean
 * `true` or a string `'true'`.
 *
 * @param  {String} name
 * @return {Boolean}
 */
export const isTruthyCookie = name => {
  return [true, "true"].includes(getCookie(name));
};

/**
 * Get the value from a cookie given its name.
 *
 * @param  {String} name
 * @return {String|null}
 */
export const getCookie = name => {
  const key = name + "=";
  var parts = document.cookie.split(";");
  for (let i = 0; i < parts.length; i++) {
    let c = parts[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(key) === 0) {
      return c.substring(key.length, c.length);
    }
  }
  return null;
};

/**
 * Set a cookie.
 *
 * Optionally, it's possible to set the cookie's max age. The value must be
 * informed in milliseconds.
 *
 * @param  {String} name
 * @param  {String} value
 * @param  {Number} [age=null]
 * @return {void}
 */
export const setCookie = (name, value, age = null) => {
  const expires = age ? "; expires=" + new Date(Date.now() + age).toUTCString() : "";
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

/**
 * Get the value from a param in the current query string.
 *
 * @param  {String} key
 * @return {String|null}
 */
export const getQueryStringParam = key => {
  const url = window.location.href;
  key = key.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + key + "(=([^&#]*)|&|#|$)");
  const matches = regex.exec(url);
  if (!matches) {
    return null;
  }
  if (!matches[2]) {
    return "";
  }
  return decodeURIComponent(matches[2].replace(/\+/g, " "));
};

/**
 * Check whether the current device is a mobile one.
 *
 * @return {Boolean}
 */
export const isMobile = () => {
  return !!/i(Phone|Pad|Pod)|Android/i.test(navigator.userAgent);
};

/**
 * Check whether the widget is embedded in a page other than the search results.
 *
 * @return {Boolean}
 */
export const isEmbedded = () => {
  return !!window.searchWidgetEmbed;
};

/**
 * Copy the value from given input to the clipboard.
 *
 * @param  {Element} input
 * @return {void}
 */
export const copyToClipboard = input => {
  const range = document.createRange();
  range.selectNodeContents(input);

  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);

  input.select();
  input.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the input

  document.execCommand("copy");
};

/**
 * Automatically start a file download.
 *
 * @param  {String} name
 * @param  {String} contents
 * @return {void}
 */
export const download = (name, contents) => {
  const a = document.createElement("a");
  a.style.cssText = "display:none";
  document.body.appendChild(a);

  const blob = new Blob([contents], { type: "octet/stream" });

  if (navigator.msSaveBlob) {
    return navigator.msSaveBlob(blob, name);
  }

  const url = window.URL.createObjectURL(blob);

  a.href = url;
  a.download = name;
  a.click();

  window.URL.revokeObjectURL(url);

  a.remove();
};

/**
 * Check whether the browser supports the IntersectionObserver API.
 *
 * @return {Boolean}
 */
export const supportIntersectionObserver = () => {
  if (typeof window !== "object") {
    return false;
  }

  return (
    "IntersectionObserver" in window &&
    "IntersectionObserverEntry" in window &&
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  );
};

/**
 * Build a HTTP query string based on an object with params.
 *
 * @param  {Object.<String, String>} params
 * @return {String}
 */
export const buildQuery = params => {
  let query = "";
  Object.keys(params).forEach(key => {
    if (query.length > 0) {
      query += "&";
    }
    query += encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
  });
  return query;
};

/**
 * Get the browser language
 *
 * @return {string}
 */
export const getBrowserLang = () => {
  return navigator.language || navigator.languages[0] || "en-US";
};

/**
 * Get the browser type
 *
 * @return {string}
 */
export const getBrowserType = () => {
  const { appName, userAgent } = navigator;
  let browserName = appName,
    verOffset = "",
    nameOffset = "";

  if (userAgent.indexOf("Opera") !== -1) {
    browserName = "Opera";
  } else if (userAgent.indexOf("MSIE") !== -1) {
    browserName = "Microsoft Internet Explorer";
  } else if (userAgent.indexOf("Chrome") !== -1) {
    browserName = "Chrome";
  } else if (userAgent.indexOf("Safari") !== -1) {
    browserName = "Safari";
  } else if (userAgent.indexOf("Firefox") !== -1) {
    browserName = "Firefox";
  } else if (
    (nameOffset = userAgent.lastIndexOf(" ") + 1) < (verOffset = userAgent.lastIndexOf("/"))
  ) {
    browserName = userAgent.substring(nameOffset, verOffset);
  }

  return browserName;
};

/**
 * Get the OS info
 *
 * @return {string}
 */
export const getOSInfo = () => {
  let OSName = "Unknown OS";
  if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
  if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
  if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";
  return OSName;
};

/**
 * Load script with promise
 *
 * @param {String} url
 * @param {String} key = null
 * @param {Object} props = {}
 * @return {Promise<Boolean>}
 */
export const loadScript = (url, key, props = {}) =>
  new Promise((resolve, reject) => {
    try {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      if (key) {
        script.id = key;
      }

      for (const [key, val] of Object.entries(props)) {
        script[key] = val;
      }

      script.onload = () => resolve(true);
      document.head.appendChild(script);
    } catch (error) {
      console.error("Error Loading script" + key, error);
      reject(false);
    }
  });

/**
 * Load script with promise
 *
 * @param {String} url
 * @param {Object} props = {}
 * @return {Promise<Boolean>}
 */
export const loadStyles = (url, key, props = {}) =>
  new Promise((resolve, reject) => {
    try {
      const link = document.createElement("link");
      // set the attributes for link element
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = url;

      for (const [key, val] of Object.entries(props)) {
        link[key] = val;
      }

      link.onload = () => resolve(true);
      document.head.appendChild(link);
    } catch (error) {
      console.error("Error Loading script", error);
      reject(false);
    }
  });

/**
 * console log based on cookie
 *
 */
export const ncDebug = (...params) => {
  try {
    if (getQueryStringParam("nc-search-debug") === "enabled") {
      console.log(...params);
    }
  } catch (e) {
    //
  }
};
