import { isValid } from "tldjs";

import Domain from "store/Domain";
// import { getPremiumTlds, getTld } from "utils/backend";
import { getTld } from "utils/backend";
import { tldExists } from "utils/domain";
import { cleanQuery } from "utils/format";
import { getProductSlug } from "utils/product";

/**
 * The search modes.
 *
 * @type {Object.<String, String>}
 */
export const MODES = {
  hns: "hns",
  auctions: "auctions",
  generator: "generator",
  standard: "standard",
  beast: "beast",
};

/**
 * The cookie name where the picks visibility is set to.
 *
 * @type {String}
 */
export const COOKIE_PICKS = "nc-pick-hide";

/**
 * The cookie name where the products visibility is set to.
 *
 * @type {String}
 */
export const COOKIE_PRODUCTS = "nc-products-hide";

/**
 * The cookie name where the HNS info visibility is set to.
 *
 * @type {String}
 */
export const COOKIE_HNS = "nc-hns-hide";

export const isValidMode = mode => {
  return MODES[mode] ? true : false;
};

/**
 * Create a domain given its SLD and TLD.
 *
 * @param  {String} sld
 * @param  {String|Object} tld
 * @param  {Boolean} [hidden=false]
 * @return {Promise<Domain>}
 */
export const createDomain = async (sld, tld, hidden = false) => {
  if (typeof tld === "string") {
    tld = await getTld(tld);
  }

  if (!tld) {
    return null;
  }

  return new Domain({
    sld: sld,
    tld: tld.Name,
    price: {
      isNormal: tld.Pricing.Enable_eap ? false : true,
      price: tld.Pricing.Price,
      regular: tld.Pricing.Regular,
      renewal: tld.Pricing.Renewal,
    },
    hint: tld.Pricing.Hint,
    tooltip: tld.Pricing.Tooltip,
    enable_eap: tld.Pricing.Enable_eap,
    sequence: tld.Sequence,
    hidden,
  });
};

/**
 * Create a list of domains.
 *
 * @param  {String} query
 * @param  {Array} [tlds=[]]
 * @return {Promise<Array<Domain>>|Promise<Array<String>>}
 */
export const createDomains = async (query, tlds = []) => {
  const parts = query.split("\n");
  if (parts.length > 1 || tlds.length === 0) {
    return await createMultipleDomains(parts);
  }

  return (
    await Promise.all(
      tlds.map(async tld => {
        return await createDomain(query, tld);
      })
    )
  ).filter(domain => !!domain);
};

/**
 * Create multiple domains from a list of FQDN.
 *
 * @param  {Array<String>} domains
 * @return {Promise<Array<String>>}
 */
export const createMultipleDomains = async domains => {
  return domains.filter(domain => domain.includes("."));
};

/**
 * Create a product object from the given pick details.
 *
 * @param  {Object} pick
 * @return {Object}
 */
export const createPickProduct = pick => {
  pick.info = JSON.parse(pick.info);
  pick.info.slug = getProductSlug(pick.info.id);
  pick.isAddedToCart = false;
  return pick;
};

/**
 * Split a domain into SLD and TLD.
 *
 * @param  {String} domain
 * @return {Array<String>}
 */
export const splitDomain = domain => {
  const parts = domain.split(".");
  parts.push(parts.splice(1).join("."));
  return parts;
};

/**
 * Prepare a query to be used.
 *
 * @param  {String} query
 * @return {String}
 */
export const prepareQuery = query => {
  return cleanQuery(query.toLowerCase().split("\n").shift());
};

/**
 * Extract the query and qualified domain for standard mode.
 *
 * @param  {String} query
 * @return {Promise<Array<String>>}
 */
export const extractStandardQuery = async (query, hns = false) => {
  query = prepareQuery(query);

  let qualified = null;
  const [sld, tld] = splitDomain(query);

  if (query[0] === ".") {
    throw new Error("Invalid entry");
  }

  if (tld.length > 0) {
    if (!hns) {
      if (!isValid(query)) {
        throw new Error("Invalid entry");
      }
    }

    /*
     * .co.bz in not in the Public Suffix List, but it's a valid TLD.
     *
     * https://publicsuffix.org/list/public_suffix_list.dat
     */
    const _tldExists = await tldExists(query);
    if (!_tldExists && !query.match(/\.co\.bz$/)) {
      throw new Error("Invalid TLD");
    } else if (!(await getTld(tld))) {
      throw new Error("Unsupported TLD");
    } else {
      qualified = query;
    }

    query = sld;
  }

  return [query, qualified];
};

/**
 * check if the TLD should use premium endpoint
 *
 * @param {String} tld
 * @return {Boolean}
 */
export const usePremiumEndpoint = async tld => {
  // const tlds = await getPremiumTlds();
  return [
    "apartments",
    "bar",
    "game",
    "how",
    "london",
    "me",
    "melbourne",
    "menu",
    "observer",
    "realty",
    "rest",
    "soy",
    "sucks",
    "sydney",
    "top",
    "uno",
  ].includes(tld);
};
